import React, { useState } from 'react'

import { ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { RowBetween } from 'components/Row'
import { BottomGrouping, BottomInfo } from 'components/swap/styleds'
import { useContext } from 'react'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { Box, setupNetwork, useActiveWeb3React, useApi, web3NoAccount } from 'shared'
import { SimplexAuthState, useSimplexAuth } from './hooks/useSimplexAuth'
import { useGetSimplexQuoteQuery } from 'hooks/queries/useGetSimplexQuoteQuery'
import { useEffectOnce } from 'react-use'
import { SimplexCheckoutModal } from '../SimplexCheckoutModal'
import { LoadingDotsIcon } from 'ui/LoadingDotsIcon'

import { useWalletModalToggle } from 'state/application/hooks'
import { ChainId } from 'sdk/constants'
import { SUPPORTED_NETWORKS_IDS } from 'connectors'
import { useTranslation } from 'react-i18next'

type Props = {
  usdAmount: string
}

export const SimplexSection: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()
  const api = useApi()
  const theme = useContext(ThemeContext)
  const { tryAuth, authState, checkAuth } = useSimplexAuth()
  const quoteQuery = useGetSimplexQuoteQuery()
  const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false)
  const [isPaymentLoading, setIsPaymentLoading] = useState(false)

  useEffectOnce(() => {
    checkAuth()
  })

  const isPaymentRequestLoading = quoteQuery.isLoading || isPaymentLoading

  const onPurchase = async () => {
    setIsPaymentLoading(true)

    try {
      await tryAuth()
      const authToken = JSON.parse(localStorage.getItem('simplex-auth-token') ?? '')

      if (quoteQuery.data) {
        const paymentResponse = await api?.simplexApi.requestPayment({
          quoteId: quoteQuery.data?.quoteId ?? '',
          quoteDate: quoteQuery.data?.validUntil ?? '',
          authentication: authToken ?? '',
        })

        setIsCheckoutModalOpen(true)

        // eslint-disable-next-line @typescript-eslint/camelcase
        window.Simplex.load({ payment_id: paymentResponse?.data.paymentId }, () => {
          setIsPaymentLoading(false)
        })

        window.Simplex.subscribe('onlineFlowFinished', () => {
          setIsCheckoutModalOpen(false)
        })
      }
    } catch (error) {
      setIsPaymentLoading(false)
      throw error
    }
  }

  const getPaymentButtonContent = () => {
    if (isPaymentRequestLoading) {
      return (
        <Box ml="10px" width="20px" height="20px">
          <LoadingDotsIcon size={20} />
        </Box>
      )
    }

    if (!props.usdAmount) {
      return t('common:enterAmount')
    }

    return 'Purchase'
  }

  const quoteRequestError = (quoteQuery.error as any)?.response?.data?.error

  const toggleWalletModal = useWalletModalToggle()

  const renderButton = () => {
    const isWrongAppNetwork = !SUPPORTED_NETWORKS_IDS.includes(chainId ?? 0)

    if (!account || isWrongAppNetwork) {
      return (
        <ButtonPrimary onClick={isWrongAppNetwork ? () => setupNetwork(chainId ?? ChainId.FANTOM) : toggleWalletModal}>
          Connect Wallet
        </ButtonPrimary>
      )
    }

    switch (authState) {
      case SimplexAuthState.INITIAL:
      case SimplexAuthState.LOADING:
        return (
          <ButtonPrimary disabled={true}>
            <Box ml="10px" width="20px" height="20px">
              <LoadingDotsIcon size={20} />
            </Box>
          </ButtonPrimary>
        )

      case SimplexAuthState.NEEDS_AUTH:
        return <ButtonPrimary onClick={tryAuth}>Authorize</ButtonPrimary>

      default:
        return (
          <ButtonPrimary
            disabled={!props.usdAmount || isPaymentRequestLoading || quoteQuery.isError}
            onClick={onPurchase}
          >
            {getPaymentButtonContent()}
          </ButtonPrimary>
        )
    }
  }

  const onCheckoutModalClose = () => {
    setIsCheckoutModalOpen(false)
    setIsPaymentLoading(false)
  }

  return (
    <Box px="20px">
      <SimplexCheckoutModal closeModal={onCheckoutModalClose} isOpen={isCheckoutModalOpen} />
      <BottomGrouping style={{ marginBottom: '16px' }}>{renderButton()}</BottomGrouping>

      {quoteQuery.error && quoteRequestError !== 'authentication header is mandatory' ? (
        <BottomInfo>
          <AutoColumn gap="4px">
            <RowBetween align="center">
              <Text fontWeight={500} fontSize={13} color={theme.secondaryText1}>
                {quoteRequestError}
              </Text>
            </RowBetween>
          </AutoColumn>
        </BottomInfo>
      ) : (
        <></>
      )}
    </Box>
  )
}
