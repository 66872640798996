import { Currency, Pair, JSBI } from 'sdk'
import { useState, useCallback } from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { useLPorCurrencyBalanceQuery } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween } from '../Row'
import { IconWrapper, TYPE } from '../../theme'
import { Input as NumericalInput } from '../NumericalInput'
import { useMediaWith } from 'theme'

import { useActiveWeb3React, useChainId } from '../../hooks'
import useTheme from '../../hooks/useTheme'
import { CurrencyDirection } from 'enums/common'
import { Box } from 'shared'
import Big from 'big.js'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { Text } from 'rebass'
import { formatDollarAmount } from 'utils/chartUtils'
import { SwapType } from 'state/swap/reducer'
import { CardIcon } from 'icons/CardIcon'
import { ArrowDownToggle } from 'icons/ArrowDownToggle'
import { useCGListedTokenPricesByNetwork } from 'hooks/coingecko/useCGListedTokenPricesByNetwork'
import { useCGTokenPrices } from 'hooks/coingecko/useCGTokenPrices'
import { RefreshIcon } from 'icons/RefreshIcon'
import { useODOSTokensPrices } from 'hooks/ODOS/useODOSTokensPrices'
import { LoadingDotsIcon } from 'ui/LoadingDotsIcon'
import { formatToCurrency } from 'utils/formatters'

const InputRow = styled.div<{ selected: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  margin-top: 5px;
`

const CurrencySelect = styled.button<{ selected: boolean }>`
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: ${({ selected, theme }) => (selected ? theme.white : theme.white)};
  border-radius: 22px;
  background-color: rgba(255, 255, 255, 0.15);
  outline: none;
  padding: 5px;
  cursor: pointer;
  user-select: none;

  transition: all 0.3s;
`

const LabelRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: ${({ theme }) => theme.text1};
  font-size: 0.75rem;
  line-height: 1rem;
  transition: all 0.3s;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.text2)};
  }
`

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledDropDown = styled(ArrowDownToggle)<{ selected: boolean }>`
  margin-left: 10px;
  margin-right: 5px;

  path {
    stroke: ${({ theme }) => theme.primaryText1};
    stroke-width: 1.5px;
  }
`

const InputPanel = styled.div<{ hideInput?: boolean }>`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  border-radius: 10px;
  background: ${({ theme }) => theme.componentBg4};
  padding: 10px;
  z-index: 1;
  max-width: 100%;
`

const StyledTokenName = styled.span<{ active?: boolean }>`
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.primaryText1};
`

const StyledBalanceMax = styled.button<{ isActive?: boolean; disabled?: boolean }>`
  background: ${({ theme }) => theme.green2};
  border-radius: 5px;
  min-height: 16px;
  font-size: 10px;
  padding: 0px 3px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  color: ${({ theme }) => theme.primaryText1};
  text-transform: uppercase;
  transition: all 0.3s;
  pointer-events: ${({ isActive }) => (isActive ? 'all' : 'none')};
  opacity: ${({ disabled, isActive }) => (disabled ? '0.4' : isActive ? '1' : '0.4')};

  &:focus {
    outline: none;
  }
`

const StyledUserBalance = styled.span<{ disabled?: boolean }>`
  font: inherit;
  color: ${({ theme }) => theme.primaryText1};
  line-height: 16px;
  margin-bottom: -1px;
  border-bottom: 1px solid transparent;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  transition: border-color 0.3s, color 0.3s;

  &:hover {
    border-color: ${({ theme, disabled }) => (disabled ? 'transparent' : theme.primaryText1)};
    color: ${({ theme }) => theme.primaryText1} !important;
  }

  &:active {
    opacity: ${({ disabled }) => (disabled ? 1 : 0.7)};
  }
`

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton?: boolean
  onHalf?: () => void
  showHalfButton?: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  customBalanceText?: string
  cornerRadiusBottomNone?: boolean
  cornerRadiusTopNone?: boolean
  containerBackground?: string
  currencyDirection?: CurrencyDirection
  hideCurrencySelect?: boolean
  inputDisabled?: boolean
  listType?: SwapType
  isRowView?: boolean
  isMarket?: boolean
  showRefresh?: boolean
  loading?: boolean
  wideSelect?: boolean
  maxLength?: number
  hasEnoughGas?: boolean
  isFetchinQuote?: boolean
}

const zeroDecimalsAmountFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

const amountFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 3,
})

export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  onHalf,
  showHalfButton,
  label = 'Input',
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  otherCurrency,
  customBalanceText,
  currencyDirection,
  hideCurrencySelect,
  inputDisabled = false,
  listType = null,
  isRowView,
  isMarket,
  showRefresh = false,
  loading = false,
  wideSelect,
  maxLength = 79,
  hasEnoughGas = true,
  isFetchinQuote,
}: CurrencyInputPanelProps) {
  const [modalOpen, setModalOpen] = useState(false)
  const { account } = useActiveWeb3React()
  const chainId = useChainId()
  const selectedCurrencyBalanceQuery = useLPorCurrencyBalanceQuery(currency ?? undefined)
  const wrappedSelectedCurrency = currency ? wrappedCurrency(currency, chainId) : null
  const { upToSmall } = useMediaWith()

  const { data: lif3PriceQuery } = useCGTokenPrices({ tokenSymbols: ['LIF3'] })
  const { fetchSelectedTokensPrices } = useODOSTokensPrices()

  const pricesQuery: any = useCGListedTokenPricesByNetwork('V3')

  const price =
    currency?.symbol === 'LIF3'
      ? lif3PriceQuery?.LIF3?.usd
      : pricesQuery?.data?.[wrappedSelectedCurrency?.address.toLowerCase() ?? '']?.usd

  const usdAmount = price && value && Number(value) ? Big(Number(value)).mul(price) : null

  const theme = useTheme()

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  const selectedCurrencyBalance = selectedCurrencyBalanceQuery.data

  // const selectedCurrencyBalance =
  //   balances[currency?.symbol === NATIVE_CURRENCY[chainId].symbol ? currency?.symbol : currency?.address]

  const hasBalance = selectedCurrencyBalance ? selectedCurrencyBalance?.greaterThan(JSBI.BigInt(0)) : false

  const getBalanceLabel = () => {
    if (!!currency && selectedCurrencyBalance) {
      const getBalance = () => {
        if (!selectedCurrencyBalance) return '0.00'

        if (currency.decimals === 0) {
          return zeroDecimalsAmountFormatter.format(Number(selectedCurrencyBalance?.toExact()))
        } else {
          if (Big(Number(selectedCurrencyBalance?.toExact())).lte(0.01)) {
            if (Big(Number(selectedCurrencyBalance?.toExact())).eq(0)) {
              return '0.0'
            }

            return '<0.01'
          } else {
            return amountFormatter.format(Number(selectedCurrencyBalance?.toExact()))
          }
        }
      }

      return `${customBalanceText ?? ''}${getBalance()}`
    }

    return '0.0'
  }

  const getSymbol = () => {
    if (currency === Currency.USD_CARD) {
      return Currency.USD_CARD.name
    }

    return (
      (currency && currency.symbol && currency.symbol.length > 20
        ? currency.symbol.slice(0, 4) +
          '...' +
          currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
        : currency?.symbol) || 'Select a token'
    )
  }

  return (
    <InputPanel>
      {!hideInput && (
        <LabelRow>
          <RowBetween justifyContent="center">
            <TYPE.body color={theme.secondaryText1} fontSize={13}>
              <Box display="flex" alignItems="center" style={{ gap: 5 }}>
                {label} {loading && <LoadingDotsIcon size={14} />}
              </Box>
            </TYPE.body>

            {currency !== Currency.USD_CARD && (
              <Box display="flex" alignItems="center">
                {!hideBalance ? (
                  <Box display="flex" justifyContent="flex-end">
                    {showRefresh && (
                      <Box style={{ color: theme.primaryText1, cursor: 'pointer' }}>
                        <IconWrapper
                          onClick={() => fetchSelectedTokensPrices()}
                          size="16px"
                          style={{ marginRight: '5px' }}
                        >
                          <RefreshIcon />
                        </IconWrapper>
                      </Box>
                    )}
                    {!hideInput && account && showHalfButton && currency && label !== 'To' && (
                      <StyledBalanceMax
                        isActive={hasBalance}
                        style={{ marginRight: '5px' }}
                        type="button"
                        onClick={onHalf}
                      >
                        50%
                      </StyledBalanceMax>
                    )}
                    {!hideInput && account && showMaxButton && currency && label !== 'To' && (
                      <StyledBalanceMax
                        isActive={hasBalance}
                        style={{ marginRight: '5px' }}
                        type="button"
                        onClick={onMax}
                        disabled={!hasEnoughGas}
                      >
                        MAX
                      </StyledBalanceMax>
                    )}
                  </Box>
                ) : null}
                <TYPE.body
                  onClick={hasEnoughGas ? onMax : undefined}
                  color={theme.primaryText1}
                  fontSize={13}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                    cursor: hasEnoughGas ? 'pointer' : 'default',
                    opacity: hasEnoughGas ? 1 : 0.5,
                  }}
                  title={selectedCurrencyBalance ? selectedCurrencyBalance.toExact() : undefined}
                >
                  {isFetchinQuote && (
                    <Box onClick={(e) => e.stopPropagation()} display="flex" alignItems="center">
                      Fetching quote <LoadingDotsIcon size={14} />
                    </Box>
                  )}
                  <IconWrapper stroke={theme.accentText} size="12px">
                    <CardIcon />
                  </IconWrapper>
                  <StyledUserBalance
                    style={{
                      cursor: !hasEnoughGas ? 'default' : 'pointer',
                    }}
                    disabled={!hasEnoughGas}
                  >
                    {getBalanceLabel()}
                  </StyledUserBalance>
                </TYPE.body>
              </Box>
            )}
          </RowBetween>
        </LabelRow>
      )}
      <InputRow style={hideInput ? { padding: '0', borderRadius: '8px' } : {}} selected={disableCurrencySelect}>
        {!hideCurrencySelect && (
          <Box
            display="inline-flex"
            alignItems="flex-end"
            flexGrow={wideSelect ? 0 : 1}
            maxWidth={wideSelect ? 'fit-content' : '140px'}
          >
            <CurrencySelect
              type="button"
              selected={!!currency}
              disabled={disableCurrencySelect}
              className="open-currency-select-button"
              onClick={() => {
                if (!disableCurrencySelect) {
                  setModalOpen(true)
                }
              }}
            >
              <Aligner>
                {pair ? (
                  <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={30} margin={true} />
                ) : currency ? (
                  <CurrencyLogo size="30px" currency={currency} />
                ) : null}
                {pair ? (
                  <StyledTokenName
                    className="pair-name-container"
                    title={`${pair?.token0.symbol}:${pair?.token1.symbol}`}
                    isWide
                  >
                    {pair?.token0.symbol}:{pair?.token1.symbol}
                  </StyledTokenName>
                ) : (
                  <StyledTokenName className="token-symbol-container" active={Boolean(currency && currency.symbol)}>
                    {getSymbol()}
                  </StyledTokenName>
                )}
                {!disableCurrencySelect ? (
                  <Box display="flex" justifyContent="center" alignItems="center" ml="10px" mr="5px">
                    <StyledDropDown selected={!!currency} />
                  </Box>
                ) : (
                  <Box />
                )}
              </Aligner>
            </CurrencySelect>
          </Box>
        )}
        {!hideInput && (
          <Box
            flexGrow={1}
            display="flex"
            justifyContent="flex-end"
            paddingLeft="10px"
            mb={currency === Currency.USD_CARD ? '17px' : 0}
          >
            <NumericalInput
              disabled={inputDisabled}
              style={{ textAlign: 'end', lineHeight: '40px' }}
              value={value}
              maxLength={maxLength}
              onUserInput={(event) => {
                onUserInput(event)
              }}
              isRowView={isRowView}
            />
          </Box>
        )}
      </InputRow>
      {currency !== Currency.USD_CARD && (
        <Box mt="5px" display="flex" justifyContent="flex-end" maxWidth="100%">
          <Text
            overflow="hidden"
            maxWidth={upToSmall ? '290px' : '100%'}
            height="16px"
            fontSize="13px"
            color={theme.primaryText1}
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {usdAmount && !pricesQuery?.isLoading
              ? `≈${formatDollarAmount(Number(usdAmount?.toString()), 2)}`
              : '$0.00'}
          </Text>
        </Box>
      )}
      {!disableCurrencySelect && onCurrencySelect && currencyDirection && (
        <CurrencySearchModal
          listType={listType}
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          activeCurrencyDirection={currencyDirection}
          market={isMarket}
        />
      )}
    </InputPanel>
  )
}
