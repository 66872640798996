import { useQuery } from 'react-query'
import { request, gql } from 'graphql-request'

import { getExchangeSubgraphUrl } from 'shared'
import Big from 'big.js'
import { useListedTokens, useUserAddedTokensMap } from 'hooks/Tokens'
import { getAddress } from '@ethersproject/address'
import { ChainId, NATIVE_CURRENCY, WNATIVE } from 'sdk'
import { useChainId } from 'hooks'
import { useCGTokenPrices } from 'hooks/coingecko/useCGTokenPrices'

type Response = {
  tokens: Array<{
    id: string
    totalLiquidity: string
    derivedETH: string
    tradeVolumeUSD: string
    txCount: string
    decimals: Number
  }>
  bundle: {
    ethPrice: string
  }
}

export const useTokensInfoQuery = () => {
  const listedTokens = useListedTokens('Market')
  const userAddedTokens = useUserAddedTokensMap()
  const chainId = useChainId()
  const nativeTokenCgPrice = useCGTokenPrices({ tokenSymbols: [NATIVE_CURRENCY[chainId].symbol] })

  const tokenAddresses = Object.values({
    [WNATIVE[chainId].address]: WNATIVE[chainId],
    ...userAddedTokens,
    ...listedTokens,
    // TODO: remove when token info subgraph is ready for eth
    ...((chainId === ChainId.ETHEREUM || chainId === ChainId.ARBITRUM || chainId === ChainId.OPTIMISM) && [
      {
        address: '0xF70B6D6AcD652612f24F7DD2CA2F1727eB20793a',
        chainId: ChainId.BSC,
        name: 'LSHARE',
        symbol: 'LSHARE',
        decimals: 18,
        logoURI: 'https://assets.lif3.com/swap/tokens/LSHARE.svg',
      },
      {
        address: '0x80D2Fe89b6C6c24edfB553DAF35599649AC55283',
        chainId: ChainId.BSC,
        name: 'LIF3',
        symbol: 'LIF3',
        decimals: 18,
        logoURI: 'https://assets.lif3.com/swap/tokens/LIF3.svg',
      },
      {
        address: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
        chainId: ChainId.BSC,
        name: 'Bitcoin',
        symbol: 'BTCB',
        decimals: 18,
        logoURI: 'https://assets.lif3.com/swap/tokens/BTC.svg',
      },
      {
        address: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
        chainId: ChainId.BSC,
        name: 'Ethereum',
        symbol: 'ETH',
        decimals: 18,
        logoURI: 'https://assets.lif3.com/swap/tokens/ETH.svg',
      },
    ]),
  })
    .map((token: any) => token.address)
    .sort((addressA, addressB) => (addressA > addressB ? 1 : -1))

  return useQuery<
    Record<
      string,
      {
        address: string
        totalLiquidityUSD: number
        txCount: number
        USDPrice: number
        totalTradeVolumeUSD: number
      }
    >
  >(
    ['get-tokens-info', ...tokenAddresses, chainId],
    async () => {
      try {
        const queryString = tokenAddresses.reduce((acc, tokenAddr) => {
          return `
          address${tokenAddr.toLowerCase()}: token(id: "${tokenAddr.toLowerCase()}") {
            id
            totalLiquidity
            derivedETH
            tradeVolumeUSD
            txCount
            decimals
          }
          ${acc}
        `
        }, '')
        const response = await request<Response>(
          getExchangeSubgraphUrl('LIF3Swap')[chainId],
          gql`
        query tokensInfo($tokensAddress: [ID!], $tokensLength: Int!) {
  
          ${queryString}
      
          bundle(id: 1) {
            ethPrice
          }
        }
      `,
          {
            tokensAddress: tokenAddresses,
            tokensLength: tokenAddresses.length,
          }
        )
        const responseObj = {}
        tokenAddresses.forEach((tokenAddr) => {
          const ethPrice =
            WNATIVE[chainId].address.toLowerCase() === tokenAddr.toLowerCase()
              ? nativeTokenCgPrice.data[NATIVE_CURRENCY[chainId].symbol]?.usd ?? 0
              : response.bundle?.ethPrice

          if (response['address' + tokenAddr.toLowerCase()] == undefined) {
            return
          }
          const totalLiquidityUSD = Big(response['address' + tokenAddr.toLowerCase()].totalLiquidity)
            .mul(response['address' + tokenAddr.toLowerCase()].derivedETH)
            .mul(ethPrice)
            .toString()

          const USDPrice = Big(response['address' + tokenAddr.toLowerCase()].derivedETH).mul(ethPrice)

          const checksummedAddress = getAddress(response['address' + tokenAddr.toLowerCase()].id)

          responseObj[checksummedAddress] = {
            address: checksummedAddress,
            totalLiquidityUSD: Number(totalLiquidityUSD),
            txCount: Number(response['address' + tokenAddr.toLowerCase()].txCount),
            USDPrice: Number(USDPrice),
            totalTradeVolumeUSD: Number(response['address' + tokenAddr.toLowerCase()].tradeVolumeUSD),
          }

          return true
        })
        return responseObj as any
      } catch (error) {
        console.error(error)
        return null
      }
    },
    {
      refetchOnMount: false,
      refetchInterval: 30000,
      refetchOnWindowFocus: false,
      enabled: Boolean(nativeTokenCgPrice.data),
    }
  )
}
