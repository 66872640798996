import { Trade } from 'sdk'
import React, { Fragment, memo, useContext } from 'react'
import { ChevronRight } from 'react-feather'
import { Flex } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { TYPE } from '../../theme'
import { useMediaWith } from 'theme'
import { unwrappedToken } from 'utils/wrappedCurrency'
import CurrencyLogo from 'components/CurrencyLogo'
import { ArrowRightIcon, Box, shortenTokenSymbol } from 'shared'
import { useChainId } from 'hooks'

const RouteItem = styled(Box)`
  // box-shadow: ${({ theme }) => theme.contrastShadow};
  height: 30px;
  border-radius: 20px;
`

export default memo(function SwapRoute({ trade }: { trade: Trade }) {
  const chainId = useChainId()
  const theme = useContext(ThemeContext)
  const { upToMedium } = useMediaWith()

  const getShortenLength = () => {
    if (upToMedium) return 3

    return 6
  }

  return (
    <Flex flexWrap="wrap" width="100%" justifyContent="flex-end" alignItems="center">
      {trade.route.path.map((token, i, path) => {
        const isLastItem: boolean = i === path.length - 1
        const currency = unwrappedToken(token, chainId)

        return (
          <Box display="flex" alignItems="center" mr={isLastItem ? 0 : '8px'} key={i}>
            <RouteItem display="flex" alignItems="center">
              <Box display="flex" alignItems="center">
                <Box mr="6px" width="18px" height="18px" display="flex" justifyContent="center" alignItems="center">
                  <CurrencyLogo size="18px" currency={currency} />
                </Box>
                <TYPE.black fontSize={12} color={theme.primaryText1}>
                  {shortenTokenSymbol(currency.getSymbol() as any, getShortenLength())}
                </TYPE.black>
              </Box>
            </RouteItem>
            {isLastItem ? null : (
              <Box
                ml="6px"
                display="flex"
                color={theme.text3}
                alignItems="center"
                justifyContent="center"
                width="12px"
                height="12px"
              >
                <ChevronRight />
              </Box>
            )}
          </Box>
        )
      })}
    </Flex>
  )
})
