import React, { useCallback, useMemo, useState } from 'react'
import { Token, Currency } from 'sdk'
import { CurrencyDirection } from 'enums/common'
import { usePrevious } from 'shared'
// import Modal from '../Modal'
import { CurrencySearch } from './CurrencySearch'
import { ImportToken } from './ImportToken'
import ManageTokens from './ManageTokens'
import Modal from 'components/Modal'
import { SwapType } from 'state/swap/reducer'

export enum CurrencyModalView {
  search,
  importToken,
  manage,
}

interface CurrencySearchModalProps {
  isOpen: boolean
  onDismiss: () => void
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Currency) => void
  otherSelectedCurrency?: Currency | null
  activeCurrencyDirection: CurrencyDirection | null
  listType?: SwapType
  market?: boolean
}

export default function CurrencySearchModal({
  isOpen,
  onDismiss,
  onCurrencySelect,
  selectedCurrency,
  otherSelectedCurrency,
  activeCurrencyDirection,
  listType = null,
  market = false,
}: CurrencySearchModalProps) {
  const [modalView, setModalView] = useState<CurrencyModalView>(CurrencyModalView.search)

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      onCurrencySelect(currency)
      onDismiss()
    },
    [onDismiss, onCurrencySelect]
  )

  // change min height if not searching

  // for token import view
  const prevView = usePrevious(modalView)

  // used for import token flow
  const [importToken, setImportToken] = useState<Token | undefined>()

  const [searchTokenQuery, setSearchTokenQuery] = useState<string>('')

  return (
    <Modal isOpen={isOpen} onRequestClose={onDismiss} minHeight="80vh">
      {modalView === CurrencyModalView.search ? (
        <CurrencySearch
          listType={listType}
          isOpen={isOpen}
          onDismiss={onDismiss}
          onCurrencySelect={handleCurrencySelect}
          selectedCurrency={selectedCurrency}
          otherSelectedCurrency={otherSelectedCurrency}
          activeCurrencyDirection={activeCurrencyDirection}
          setSearchTokenQuery={setSearchTokenQuery}
          showImportView={() => {
            setModalView(CurrencyModalView.manage)
          }}
          showImportSelectedTokenView={() => {
            setModalView(CurrencyModalView.importToken)
          }}
          setImportToken={setImportToken}
          market={market}
        />
      ) : modalView === CurrencyModalView.importToken && importToken ? (
        <ImportToken
          tokens={[importToken]}
          onDismiss={onDismiss}
          onBack={() =>
            setModalView(prevView && prevView !== CurrencyModalView.importToken ? prevView : CurrencyModalView.search)
          }
          handleCurrencySelect={(currency: any) => {
            handleCurrencySelect(currency)

            setModalView(CurrencyModalView.search)
          }}
        />
      ) : modalView === CurrencyModalView.manage ? (
        <ManageTokens searchTokenQuery={searchTokenQuery} setModalView={setModalView} setImportToken={setImportToken} />
      ) : null}
    </Modal>
  )
}
