import { useMemo } from 'react'
import { computePoolAddress } from '@uniswap/v3-sdk'
import IUniswapV3PoolStateJSON from '@uniswap/v3-core/artifacts/contracts/interfaces/pool/IUniswapV3PoolState.sol/IUniswapV3PoolState.json'
import { Pool } from '@uniswap/v3-sdk'
import JSBI from 'jsbi'
import { useQuery } from 'react-query'
import { POOL_INIT_CODE_HASH, V3_CORE_FACTORY_ADDRESSES } from 'constants/v3/addresses'
import { useChainId } from 'hooks'
import { useToken } from 'hooks/Tokens'
import { multicallFailSafe } from 'shared'
import { PROVIDERS_BY_CHAIN } from 'connectors'
import { getAddress } from '@ethersproject/address'

export enum PoolState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID,
}

// Classes are expensive to instantiate, so this caches the recently instantiated pools.
// This avoids re-instantiating pools as the other pools in the same request are loaded.
class PoolCache {
  // Evict after 128 entries. Empirically, a swap uses 64 entries.
  static MAX_ENTRIES = 128

  // These are FIFOs, using unshift/pop. This makes recent entries faster to find.
  static pools: Pool[] = []
  static addresses: any = []

  static getPoolAddress(factoryAddress, tokenA, tokenB, fee, chainId) {
    if (this.addresses.length > this.MAX_ENTRIES) {
      this.addresses = this.addresses.slice(0, this.MAX_ENTRIES / 2)
    }

    const { address: addressA } = tokenA
    const { address: addressB } = tokenB
    const key = `${factoryAddress}:${addressA}:${addressB}:${fee.toString()}`
    const found = this.addresses.find((address) => address.key === key)
    if (found) return found.address

    const address = {
      key,
      address: computePoolAddress({
        factoryAddress,
        tokenA,
        tokenB,
        fee,
        initCodeHashManualOverride: POOL_INIT_CODE_HASH[chainId],
      }),
    }

    this.addresses.unshift(address)

    return address.address
  }

  static getPool(tokenA, tokenB, fee, sqrtPriceX96, liquidity, tick) {
    if (this.pools.length > this.MAX_ENTRIES) {
      this.pools = this.pools.slice(0, this.MAX_ENTRIES / 2)
    }
    const found = this.pools.find(
      (pool) =>
        pool.token0 === tokenA &&
        pool.token1 === tokenB &&
        pool.fee === fee &&
        JSBI.EQ(pool.sqrtRatioX96, sqrtPriceX96) &&
        JSBI.EQ(pool.liquidity, liquidity) &&
        pool.tickCurrent === tick
    )
    if (found) return found
    try {
      const formattedTick = Number(tick.toString().slice(0, String(tick).length))
      const formattedSqrtPrice = JSBI.BigInt(sqrtPriceX96.toString())
      const formattedLiquidity = JSBI.BigInt(liquidity.toString())
      const pool = new Pool(tokenA, tokenB, fee, formattedSqrtPrice, formattedLiquidity, formattedTick)
      this.pools.unshift(pool)
      return pool
    } catch (err) {
      console.log('Error - ', err)
      return null
    }
  }
}

export function usePools(poolKeys): [PoolState, Pool, string][] {
  const chainId = useChainId()

  const poolTokens = useMemo(() => {
    if (!chainId) return new Array(poolKeys.length)

    return poolKeys
      .map(([currencyA, currencyB, feeAmount]) => {
        if (currencyA && currencyB && feeAmount) {
          const tokenA = currencyA?.wrapped
          const tokenB = currencyB?.wrapped

          if (tokenA?.equals(tokenB)) return undefined

          return tokenA.sortsBefore(tokenB) ? [tokenA, tokenB, feeAmount] : [tokenB, tokenA, feeAmount]
        }

        return undefined
      })
      .filter((pair) => pair !== undefined)
  }, [chainId, poolKeys])

  const poolAddresses = useMemo(() => {
    const v3CoreFactoryAddress = chainId && V3_CORE_FACTORY_ADDRESSES[chainId]

    if (!v3CoreFactoryAddress) return new Array(poolTokens.length)

    // @ts-ignore
    return poolTokens.map((value) => value && PoolCache.getPoolAddress(v3CoreFactoryAddress, ...value, chainId))
  }, [chainId, poolTokens])

  const slot0sQuery = useQuery(
    ['v3', 'slot0', [...poolAddresses].sort()],
    async () => {
      if (!poolAddresses.length) return { slot0s: [], liquidities: [] }

      const callsSlot0 = poolAddresses.map((poolAddress) => {
        return { address: poolAddress, name: 'slot0' }
      })

      const callsLiquidity = poolAddresses.map((poolAddress) => {
        return { address: poolAddress, name: 'liquidity' }
      })

      try {
        const allResponse = await multicallFailSafe(
          IUniswapV3PoolStateJSON.abi,
          [...callsSlot0, ...callsLiquidity],
          PROVIDERS_BY_CHAIN[chainId],
          chainId
        )

        const slot0s = allResponse.slice(0, callsSlot0.length)
        const liquidities = allResponse.slice(callsLiquidity.length)

        return { slot0s: slot0s ?? [], liquidities: liquidities ?? [] }
      } catch (error) {
        console.error('slot0 error', error)
        return null
      }
    },
    {
      enabled: poolAddresses.length !== 0 && Boolean(poolAddresses) && Boolean(poolAddresses[0]),
      refetchOnMount: false,
    }
  )

  const { slot0s, liquidities } = slot0sQuery.data ?? {}

  return poolKeys.map((_key, index) => {
    const tokens = poolTokens[index]

    if (!tokens) return [PoolState.INVALID, null]
    const [token0, token1, fee] = tokens

    if (!slot0s?.[index]) return [PoolState.INVALID, null]
    const slot0 = slot0s[index]
    if (!liquidities?.[index]) return [PoolState.INVALID, null]
    const liquidity = liquidities[index]
    const sqrtPriceX96 = slot0[0]
    const tick = slot0[1]
    if (!tokens || !slot0 || !liquidity) return [PoolState.INVALID, null]
    if (slot0sQuery.isLoading) return [PoolState.LOADING, null]
    if (!slot0 || !liquidity) return [PoolState.NOT_EXISTS, null]
    if (!sqrtPriceX96) return [PoolState.NOT_EXISTS, null]

    try {
      const pool = PoolCache.getPool(token0, token1, fee, sqrtPriceX96, liquidity[0], tick)
      const factoryAddress = chainId && V3_CORE_FACTORY_ADDRESSES[chainId]
      const poolId = getAddress(PoolCache.getPoolAddress(factoryAddress, token0, token1, fee, chainId))

      return [PoolState.EXISTS, pool, poolId]
    } catch (error) {
      return [PoolState.NOT_EXISTS, null]
    }
  })
}

export function usePool(currencyA, currencyB, feeAmount): [PoolState, Pool, string] {
  const poolKeys = useMemo(() => [[currencyA, currencyB, feeAmount]], [currencyA, currencyB, feeAmount])
  const pools = usePools(poolKeys)

  return pools[0]
}

export function useTokensSymbols(token0, token1) {
  const _token0 = useToken(token0)
  const _token1 = useToken(token1)

  return useMemo(() => [_token0, _token1], [_token0, _token1])
}
