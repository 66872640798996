import { useState, useRef, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

import { TYPE } from '../../theme'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'

import { useUserSlippageTolerance, useUserTransactionTTL } from 'state/user/hooks'
import { Box } from 'shared'
import { formatToCurrency } from 'utils/formatters'

enum SlippageError {
  InvalidInput = 'InvalidInput',
  RiskyLow = 'RiskyLow',
  RiskyHigh = 'RiskyHigh',
}

enum DeadlineError {
  InvalidInput = 'InvalidInput',
}

const FancyButton = styled.button<{ active: boolean }>`
  color: ${({ theme, active }) => (active ? theme.text1 : theme.accentText)};
  align-items: center;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 400;
  width: auto;
  min-width: 0px;
  flex: 1;
  height: 32px;
  padding: 0 9px;
  background: ${({ theme, active }) => (active ? theme.white : 'transparent')};
  outline: none;
`

const Option = styled(FancyButton)<{ active: boolean }>`
  transition: color 0.3s;

  :hover {
    cursor: pointer;
  }
`

const Input = styled.input`
  padding: 0;
  margin: 0;
  background: none;
  font-size: 14px;
  width: auto;
  outline: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  color: ${({ theme, color }) => (color === theme.red1 ? theme.red1 : theme.primaryText1)};
  text-align: right;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.secondaryText1};
  }
`

const OptionCustom = styled(FancyButton)<{ active?: boolean; warning?: boolean }>`
  position: relative;
  flex: 1;
  background: transparent;
  min-width: 75px;
  border: 1px solid
    ${({ theme, active, warning }) => {
      if (warning) return theme.red1

      return active ? theme.accentText : 'transparent'
    }};
  color: inherit;

  input {
    width: 100%;
    height: 100%;
    border: 0px;
    border-radius: 18px;
  }
`

const SlippageText = styled(Box)`
  margin-right: 15px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    margin-right: 8px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const SlippageAcceptButton = styled.button<{ disabled: boolean }>`
  padding: 5.5px 10px;
  color: ${({ theme }) => theme.accent};
  border-radius: 22px;
  background: ${({ theme }) => theme.inputBg};
  border: none;
  font-size: 10px;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`

export default function SlippageTabs({
  currencies,
}: {
  currencies: { tokenA: Currency | null | undefined; tokenB: Currency | null | undefined }
}) {
  const theme = useContext(ThemeContext)
  const [userSlippageTolerance, setUserSlippageTolerance] = useUserSlippageTolerance()
  const [ttl, setTtl] = useUserTransactionTTL()
  const inputRef = useRef<HTMLInputElement | null>(null)

  const [recommendedSlippage, setRecommendedSlippage] = useState<number | null>(null)
  const [slippageInput, setSlippageInput] = useState('')
  const [deadlineInput, setDeadlineInput] = useState('')

  // useDeepCompareEffect(() => {
  //   if (currencies.tokenA && currencies.tokenB) {
  //     const maxSlippage = Math.max(currencies.tokenA.slippage || 0, currencies.tokenB.slippage || 0)

  //     setRecommendedSlippage(maxSlippage)
  //   }
  // }, [setRecommendedSlippage, currencies])

  const slippageInputIsValid =
    slippageInput === '' || (userSlippageTolerance / 100).toFixed(2) === Number.parseFloat(slippageInput).toFixed(2)
  const deadlineInputIsValid = deadlineInput === '' || (ttl / 60).toString() === deadlineInput

  let slippageError: SlippageError | undefined
  if (slippageInput !== '' && !slippageInputIsValid) {
    slippageError = SlippageError.InvalidInput
  } else if (slippageInputIsValid && userSlippageTolerance < 50) {
    slippageError = SlippageError.RiskyLow
  } else if (slippageInputIsValid && userSlippageTolerance > 500) {
    slippageError = SlippageError.RiskyHigh
  } else {
    slippageError = undefined
  }

  let deadlineError: DeadlineError | undefined
  if (deadlineInput !== '' && !deadlineInputIsValid) {
    deadlineError = DeadlineError.InvalidInput
  } else {
    deadlineError = undefined
  }

  function parseCustomSlippage(value: string) {
    setSlippageInput(value)

    try {
      const valueAsIntFromRoundedFloat = Number.parseInt((Number.parseFloat(value) * 100).toString())

      if (
        !Number.isNaN(valueAsIntFromRoundedFloat) &&
        valueAsIntFromRoundedFloat < 5000 &&
        valueAsIntFromRoundedFloat >= 0
      ) {
        setUserSlippageTolerance(valueAsIntFromRoundedFloat)
      }
    } catch {}
  }

  return (
    <AutoColumn gap="md">
      <AutoColumn gap="sm">
        <RowBetween>
          <SlippageText display="flex" alignItems="center">
            <TYPE.black fontWeight={400} fontSize={13} color={theme.primaryText1}>
              Slippage tolerance
            </TYPE.black>
          </SlippageText>
          <Option
            onClick={() => {
              setSlippageInput('')
              setUserSlippageTolerance(10)
            }}
            active={userSlippageTolerance === 10}
          >
            0.1%
          </Option>
          <Option
            onClick={() => {
              setSlippageInput('')
              setUserSlippageTolerance(50)
            }}
            active={userSlippageTolerance === 50}
          >
            0.5%
          </Option>
          <Option
            onClick={() => {
              setSlippageInput('')
              setUserSlippageTolerance(100)
            }}
            active={userSlippageTolerance === 100}
          >
            1%
          </Option>
          <OptionCustom
            active={![10, 50, 100].includes(userSlippageTolerance)}
            warning={!slippageInputIsValid}
            tabIndex={-1}
          >
            <RowBetween>
              <Input
                ref={inputRef as any}
                placeholder={formatToCurrency(Number((userSlippageTolerance / 100).toFixed(2)))}
                value={formatToCurrency(Number(slippageInput))}
                onBlur={() => {
                  parseCustomSlippage((userSlippageTolerance / 100).toFixed(2))
                }}
                onChange={(e) => parseCustomSlippage(e.target.value)}
                color={!slippageInputIsValid ? theme.red1 : ''}
              />
              %
            </RowBetween>
          </OptionCustom>
        </RowBetween>
        {/* {recommendedSlippage > 0 && slippageInput < recommendedSlippage && (
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <TYPE.black fontWeight={400} fontSize={12} mr="4px" color={theme.secondaryText1}>
              Recommended slippage:
            </TYPE.black>
            <TYPE.main fontWeight={400} fontSize={12} mr="6px" color={theme.primaryText1}>
              {`${recommendedSlippage}%`}
            </TYPE.main>
            <SlippageAcceptButton
              disabled={userSlippageTolerance === 500}
              onClick={() => parseCustomSlippage(`${recommendedSlippage}`)}
            >
              Accept
            </SlippageAcceptButton>
          </Box>
        )} */}
        {!!slippageError && (
          <Box color={slippageError === SlippageError.InvalidInput ? theme.red1 : '#FF9D42'} mt="4px" fontSize="12px">
            {slippageError === SlippageError.InvalidInput
              ? 'Enter a valid slippage percentage'
              : slippageError === SlippageError.RiskyLow
              ? 'Your transaction may fail'
              : 'Your transaction may be frontrun'}
          </Box>
        )}
      </AutoColumn>
    </AutoColumn>
  )
}
