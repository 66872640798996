import { useChainId } from 'hooks'
import { useCGListedTokenPricesByNetwork } from 'hooks/coingecko/useCGListedTokenPricesByNetwork'
import { useCGTokenPrices } from 'hooks/coingecko/useCGTokenPrices'
import { usePoolsListQuery } from 'hooks/queries/v3/usePoolsListQuery'
import { useStakingIncentivesQuery } from 'hooks/queries/v3/useStakingIncentivesQuery'
import { NATIVE_CURRENCY } from 'sdk'

export const useIncentivizedPools = () => {
  const chainId = useChainId()
  const cgNativeTokenPrice = useCGTokenPrices({ tokenSymbols: [NATIVE_CURRENCY[chainId].symbol] })
  const { isLoading: isLoadingPoolsList, data: poolsList } = usePoolsListQuery()
  const { isLoading: isLoadingIncentivesList, data: incentivesList } = useStakingIncentivesQuery('active')
  const { isLoading: isLoadingListedTokensPrices, data: listedTokensPrices } = useCGListedTokenPricesByNetwork(
    'V3'
  ) as any

  const now = new Date().getTime()
  const actualIncentives =
    incentivesList?.incentives.filter((incentive) => Number(incentive.endTime) * 1000 > now) ?? []
  const incentivizedPoolIds = actualIncentives.map((incentive) => incentive.pool) ?? []
  const incentivizedPools = poolsList?.pools.filter((pool) => incentivizedPoolIds.includes(pool.id)) ?? []
  const nativeTokenPrice = cgNativeTokenPrice.data?.[NATIVE_CURRENCY[chainId].symbol]?.usd ?? 0

  const result = incentivizedPools.map((pool) => {
    const token0Price = listedTokensPrices?.[pool.token0.id]?.usd ?? 0
    const token1Price = listedTokensPrices?.[pool.token1.id]?.usd ?? 0

    const volumeToken0Usd = Number(pool.volumeToken0) * token0Price
    const volumeToken1Usd = Number(pool.volumeToken1) * token1Price

    const feesToken0Usd = Number(pool.collectedFeesToken0) * token0Price
    const feesToken1Usd = Number(pool.collectedFeesToken1) * token1Price

    const volumeUsd = volumeToken0Usd + volumeToken1Usd
    const feesUsd = feesToken0Usd + feesToken1Usd

    return { ...pool, tvlUsd: Number(pool.totalValueLockedETH) * nativeTokenPrice, volumeUsd, feesUsd }
  })

  return result
}
