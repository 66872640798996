import React from 'react'
import styled from 'styled-components'

const TimerWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Circle = styled.svg`
  transform: rotate(-90deg);
`

const Text = styled.div`
  position: absolute;
  font-size: 10px;
  font-weight: bold;
  color: ${(props) => props.color || '#000'};
`

type Props = {
  size?: number
  strokeWidth?: number
  color?: string
  trailColor?: string
  progress: number
  remainingTime: number | null
}

const CountdownCircleTimer: React.FC<Props> = ({
  size = 100,
  strokeWidth = 8,
  color = '#4caf50',
  trailColor = '#ddd',
  progress,
  remainingTime,
}) => {
  const radius = (size - strokeWidth) / 2
  const circumference = 2 * Math.PI * radius

  return (
    <TimerWrapper style={{ width: size, height: size }}>
      <Circle width={size} height={size}>
        <circle cx={size / 2} cy={size / 2} r={radius} stroke={trailColor} strokeWidth={strokeWidth} fill="none" />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke={color}
          strokeWidth={strokeWidth}
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={(1 - progress / 100) * circumference}
          strokeLinecap="round"
        />
      </Circle>
      {remainingTime !== null && <Text color={color}>{remainingTime}</Text>}
    </TimerWrapper>
  )
}

export default CountdownCircleTimer
