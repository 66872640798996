import { currentMainNetwork, Token } from 'sdk'
import axios from 'axios'
import { useQuery } from 'react-query'
import { ChainId, CHARTS_API_URL } from 'sdk/constants'
import { WFTM_ADDRESS } from 'constants/contracts'

export type Exchange = {
  exchange: string
  ftmPair?: string
  ftmReserve: string
  usdcPair?: string
  usdcReserve: string
  fusdtPair?: string
}

export type SearchToken = {
  address: string
  decimals: number
  exchanges: Record<string, Exchange | null>
  level: number
  logoURI: string
  name: string
  slippage: 0
  socials: Record<string, string>
  symbol: string
  website: string
  price?: number
  priceChange: number | null
}
export type SearchResponse = { records: Record<string, SearchToken>[] }

const METIS_ADDRESS = '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000'

export const useGetSelectedChartToken = (inputCurrency: Token | undefined, outputCurrency: Token | undefined) => {
  const getTokenToSearch = () => {
    if (inputCurrency?.address === '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75') {
      // USDC
      return outputCurrency?.address
    }

    if (outputCurrency?.address === '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75') {
      // USDC
      return inputCurrency?.address
    }
    /*
    if (outputCurrency?.address === '0xbB06DCA3AE6887fAbF931640f67cab3e3a16F4dC') {
      // USDT
      return inputCurrency?.address
    }

    if (inputCurrency?.address === '0xbB06DCA3AE6887fAbF931640f67cab3e3a16F4dC') {
      // USDT
      return outputCurrency?.address
    }
*/
    if (inputCurrency?.address === WFTM_ADDRESS[ChainId.POLYGON]) {
      // METIS
      return outputCurrency?.address
    }

    if (outputCurrency?.address === WFTM_ADDRESS[ChainId.POLYGON]) {
      // METIS
      return inputCurrency?.address
    }

    return outputCurrency?.address
  }

  return useQuery(
    ['get-selected-pair-search', inputCurrency?.address, outputCurrency?.address],
    async () => {
      const response = await axios.get<SearchResponse>(CHARTS_API_URL[currentMainNetwork] + '/search', {
        params: {
          query: getTokenToSearch(),
          size: 30,
          page: 1,
          type: 'crypto',
        },
      })

      const tokenAddress = Object.values(response.data.records[0])[0].address

      const pairs = Object.values(response.data.records[0])[0].exchanges.TombSwap

      return {
        hasMetisPair: Boolean(pairs?.ftmPair),
        tokenAddress,
        pairAddress:
          tokenAddress === WFTM_ADDRESS[ChainId.POLYGON] ? pairs?.usdcPair : pairs?.ftmPair ?? pairs?.usdcPair,
      }
    },
    { enabled: Boolean(inputCurrency && outputCurrency) }
  )
}
