import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import Big from 'big.js'
import { isMobile } from 'react-device-detect'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { NetworkContextName } from './constants'
import App from './pages/App'
import store from './state'

import TransactionUpdater from './state/transactions/updater'
import UserUpdater from './state/user/updater'
import ThemeProvider, { FixedGlobalStyle, ThemedGlobalStyle } from './theme'
import getLibrary from './utils/getLibrary'
import { RefreshContextProvider } from 'pages/Farms/context/RefreshContext'
import * as plugins from 'plugins'

import './style.css'
import './index.css'
import i18nInstance from 'i18n'
import './app.scss'
import 'react-loading-skeleton/dist/skeleton.css'
import { I18nextProvider } from 'react-i18next'

plugins.setup()

Big.NE = -20
Big.PE = 100
Big.DP = 100

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: true,
    },
  },
})

if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false
}

function Updaters() {
  return (
    <>
      <UserUpdater />
      <TransactionUpdater />
    </>
  )
}

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  // <StrictMode>
  <>
    <FixedGlobalStyle />
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <Provider store={store}>
          <ThemeProvider>
            <ThemedGlobalStyle isMobile={isMobile} />
            <BrowserRouter>
              <I18nextProvider i18n={i18nInstance}>
                <QueryClientProvider client={queryClient}>
                  <RefreshContextProvider>
                    <Updaters />
                    <App />
                  </RefreshContextProvider>
                </QueryClientProvider>
              </I18nextProvider>
            </BrowserRouter>
          </ThemeProvider>
        </Provider>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  </>
  // </StrictMode>
)
