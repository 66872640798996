import { AbstractConnector } from '@web3-react/abstract-connector'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { Box, usePrevious } from 'shared'
import { ButtonPrimary } from 'components/Button'
import useTheme from 'hooks/useTheme'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Text } from 'rebass'
import styled from 'styled-components'
import { setupNetwork } from 'shared'
import MetamaskIcon from '../../assets/images/metamask.png'
import { injected } from '../../connectors'
import { SUPPORTED_WALLETS } from '../../constants'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useWalletModalToggle } from '../../state/application/hooks'

import Modal from '../Modal'
import Option from './Option'
import PendingView from './PendingView'
import { ChainId } from 'sdk'
import { ArrowBackModalIcon } from 'icons/ArrowBackModalIcon'
import { useUserWalletConnection } from 'hooks'

const CloseIcon = styled.div`
  width: 20px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    opacity: 0.6;
  }
`

const CloseColor = styled(ArrowBackModalIcon)`
  width: 19px;
  height: 14px;
`

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
`

const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  gap: 8px;
  justify-content: space-between;
  padding: 15px 20px;
  border-radius: 15px 15px 0 0;
  font-weight: 500;
  background: ${({ theme }) => theme.componentBg4};

  color: ${(props) => (props.color === 'blue' ? ({ theme }) => theme.accent2 : 'inherit')};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  ${({ theme }) => theme.mediaWidth.upToMedium`padding: 1rem`};
`

const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

const OptionGrid = styled.div`
  display: grid;
  grid-gap: 15px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    grid-gap: 10px;
  `};
`

const HoverText = styled.div`
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0.15px;

  &:hover {
    cursor: pointer;
  }
`

const WALLET_VIEWS = {
  OPTIONS: 'options',
  OPTIONS_SECONDARY: 'options_secondary',
  ACCOUNT: 'account',
  PENDING: 'pending',
}

export default function WalletModal({ selectedChainId }: { selectedChainId: ChainId }) {
  // important that these are destructed from the account-specific web3-react context
  const { active, account, connector, activate, error } = useWeb3React()
  const theme = useTheme()

  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT)

  const [pendingWallet, setPendingWallet] = useState<AbstractConnector | undefined>()

  const [pendingError, setPendingError] = useState<boolean>()

  const walletModalOpen = useModalOpen(ApplicationModal.WALLET)
  const toggleWalletModal = useWalletModalToggle()

  const previousAccount = usePrevious(account)

  const { setUserWalletConnection } = useUserWalletConnection()

  // close on connection, when logged out before
  useEffect(() => {
    if (account && !previousAccount && walletModalOpen) {
      toggleWalletModal()
    }
  }, [account, previousAccount, toggleWalletModal, walletModalOpen])

  // always reset to account view
  useEffect(() => {
    if (walletModalOpen) {
      setPendingError(false)
      setWalletView(WALLET_VIEWS.ACCOUNT)
    }
  }, [walletModalOpen])

  // close modal when a connection is successful
  const activePrevious = usePrevious(active)
  const connectorPrevious = usePrevious(connector)
  useEffect(() => {
    if (walletModalOpen && ((active && !activePrevious) || (connector && connector !== connectorPrevious && !error))) {
      setWalletView(WALLET_VIEWS.ACCOUNT)
    }
  }, [setWalletView, active, error, connector, walletModalOpen, activePrevious, connectorPrevious])

  const tryActivation = async (connector: AbstractConnector | undefined) => {
    Object.keys(SUPPORTED_WALLETS).map((key) => {
      if (connector === SUPPORTED_WALLETS[key].connector) {
        return !!SUPPORTED_WALLETS[key].name
      }
      return true
    })

    setPendingWallet(connector) // set wallet for pending view
    setWalletView(WALLET_VIEWS.PENDING)

    // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
    if (connector instanceof WalletConnectConnector && connector.walletConnectProvider?.isWalletConnect) {
      connector.walletConnectProvider = undefined
    }

    connector &&
      activate(connector, async (error) => {
        if (error instanceof UnsupportedChainIdError) {
          const hasSetup = await setupNetwork(selectedChainId)

          if (hasSetup) {
            activate(connector)
          }
        } else {
          setPendingError(true)
        }

        setUserWalletConnection(true)
      })
  }

  // get wallets user can switch too, depending on device/browser
  function getOptions() {
    const isMetamask = window.ethereum && window.ethereum.isMetaMask
    return Object.keys(SUPPORTED_WALLETS).map((key) => {
      const option = SUPPORTED_WALLETS[key]

      // check for mobile options
      if (isMobile) {
        return (
          <Option
            onClick={() => {
              option.connector !== connector && !option.href && tryActivation(option.connector)
            }}
            id={`connect-${key}`}
            key={key}
            active={option.connector && option.connector === connector}
            color={option.color}
            link={option.href}
            header={option.name}
            subheader={null}
            icon={option.icon}
          />
        )
      }

      // overwrite injected when needed
      if (option.connector === injected) {
        // don't show injected if there's no injected provider
        if (!(window.web3 || window.ethereum)) {
          if (option.name === 'MetaMask') {
            return (
              <Option
                id={`connect-${key}`}
                key={key}
                color={'#E8831D'}
                header={'Install Metamask'}
                subheader={null}
                link={'https://metamask.io/'}
                icon={MetamaskIcon}
              />
            )
          } else {
            return null //dont want to return install twice
          }
        }
        // don't return metamask if injected provider isn't metamask
        else if (option.name === 'MetaMask' && !isMetamask) {
          return null
        }
        // likewise for generic
        else if (option.name === 'Injected' && isMetamask) {
          return null
        }
      }

      // return rest of options
      return (
        !isMobile &&
        !option.mobileOnly && (
          <Option
            id={`connect-${key}`}
            onClick={() => {
              option.connector === connector
                ? setWalletView(WALLET_VIEWS.ACCOUNT)
                : !option.href && tryActivation(option.connector)
            }}
            key={key}
            active={option.connector === connector}
            color={option.color}
            link={option.href}
            header={option.name}
            subheader={null} //use option.descriptio to bring back multi-line
            icon={option.icon}
          />
        )
      )
    })
  }

  function getModalContent() {
    if (error) {
      return (
        <UpperSection>
          <HeaderRow>
            <CloseIcon onClick={toggleWalletModal}>
              <CloseColor />
            </CloseIcon>
            <HoverText>{error instanceof UnsupportedChainIdError ? 'Wrong Network' : 'Error connecting'}</HoverText>
            <Box width="20px"></Box>
          </HeaderRow>
          <ContentWrapper>
            {error instanceof UnsupportedChainIdError ? (
              <Box display="flex" flexDirection="column">
                <Box mb={4}>
                  <Text color={theme.primaryText1}>
                    To use our blockchain features, you need to be on the <b>Fantom</b> network{' '}
                  </Text>
                </Box>

                <ButtonPrimary
                  onClick={async () => {
                    await setupNetwork(selectedChainId)
                  }}
                >
                  Switch network
                </ButtonPrimary>
              </Box>
            ) : (
              <Text textAlign="center" fontSize="15px" color={theme.accentText}>
                Error connecting. Try refreshing the page.
              </Text>
            )}
          </ContentWrapper>
        </UpperSection>
      )
    }

    return (
      <UpperSection>
        <HeaderRow>
          {walletView !== WALLET_VIEWS.ACCOUNT ? (
            <>
              <CloseIcon
                onClick={() => {
                  setPendingError(false)
                  setWalletView(WALLET_VIEWS.ACCOUNT)
                }}
              >
                <CloseColor />
              </CloseIcon>
            </>
          ) : (
            <CloseIcon onClick={toggleWalletModal}>
              <CloseColor />
            </CloseIcon>
          )}
          <HoverText>Connect Wallet</HoverText>

          <Box width="20px"></Box>
        </HeaderRow>

        <ContentWrapper>
          {walletView === WALLET_VIEWS.ACCOUNT && (
            <Text textAlign="center" fontSize="19px" fontWeight={700} letterSpacing="0.19px">
              Select Wallet
            </Text>
          )}
          {walletView === WALLET_VIEWS.PENDING ? (
            <PendingView
              connector={pendingWallet}
              error={pendingError}
              setPendingError={setPendingError}
              tryActivation={tryActivation}
            />
          ) : (
            <OptionGrid>{getOptions()}</OptionGrid>
          )}
        </ContentWrapper>
      </UpperSection>
    )
  }

  return (
    <Modal isOpen={walletModalOpen} onRequestClose={toggleWalletModal} borderRadius="15px">
      <Wrapper>{getModalContent()}</Wrapper>
    </Modal>
  )
}
