import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { useSelectedCurrencies, useSwapActionHandlers } from 'state/swap/hooks'
import { CurrencyDirection } from 'enums/common'
import { PairSelectCurrency } from './PairSelectCurrency'
import { Box } from 'shared'
import CurrencySearchModal from 'components/SearchModal/CurrencySearchModal'
import { useLocation } from 'react-router'
import { Text } from 'rebass'
import useTheme from 'hooks/useTheme'
import Toggle from 'components/Toggle'
import { useListedTokens } from 'hooks/Tokens'
import { Token } from 'sdk'
import { METIS_ADDRESS } from 'constants/contracts'

const CurrencySelectWrapper = styled.div`
  display: flex;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`

const CurrencySelect = styled.button<{ selected: boolean }>`
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
  border-radius: 22px;
  background-color: unset;
  outline: none;
  padding: 4.5px 12.75px;
  cursor: pointer;
  user-select: none;
  border: ${({ theme }) => `1px solid ${theme.tabsBorder}`};
  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    font-size: 12px;
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-grow: 1;
    font-size: 14px;
  `};
`

const StyledSwitchButton = styled.button<{ isActive: boolean }>`
  color: ${({ theme, isActive }) => (isActive ? theme.text1 : theme.text2)};
  border-radius: 20px;
  cursor: pointer;
  max-height: 36px;
  padding: 6px 27px 6px 27px;
  background-color: ${({ theme, isActive }) => (isActive ? theme.primary1 : 'transparent')};
  font-weight: 500;
  border: none;
  z-index: 1;
  font-size: 14px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 6px 12px;
  `};
`

type Props = {
  disableCurrencySelect?: boolean
  isUsdChart: boolean
  setIsUsdChart: (val: boolean) => void
  selectedChartToken: string | undefined
  hasMetisPair: boolean | undefined
}

export const PairSelect: React.FC<Props> = ({ disableCurrencySelect = false, ...props }) => {
  const { inputCurrency, outputCurrency } = useSelectedCurrencies()
  const { onCurrencySelection } = useSwapActionHandlers()
  const location = useLocation()
  const [activeCurrencyDirection, setActiveCurrencyDirection] = useState<CurrencyDirection | null>(null)
  const theme = useTheme()

  const showModal = Boolean(activeCurrencyDirection)

  const selectedCurrency = activeCurrencyDirection === CurrencyDirection.INPUT ? inputCurrency : outputCurrency

  const onCurrencySelect = (currency) => {
    if (activeCurrencyDirection) {
      onCurrencySelection(activeCurrencyDirection, currency)
    }
  }

  const onDismiss = () => {
    setActiveCurrencyDirection(null)
  }

  useEffect(() => {
    if (props.selectedChartToken === METIS_ADDRESS || !props.hasMetisPair) {
      return props.setIsUsdChart(true)
    }

    props.setIsUsdChart(false)
  }, [props.selectedChartToken])

  return (
    <CurrencySelectWrapper>
      <PairSelectCurrency
        disableCurrencySelect={true}
        // @ts-ignore
        currency={outputCurrency?.address === props.selectedChartToken ? outputCurrency : inputCurrency}
      />

      <Box mx="7px" display="flex" alignItems="center">
        <Text color={theme.primaryText1} fontWeight={500}>
          —
        </Text>
      </Box>
      {/* <CurrencySelect
        type="button"
        selected={!!outputCurrency}
        className="open-currency-select-button"
        onMouseDown={() => {
          if (!disableCurrencySelect) {
            setActiveCurrencyDirection(CurrencyDirection.OUTPUT)
          }
        }}
      >
        <PairSelectCurrency disableCurrencySelect={disableCurrencySelect} currency={outputCurrency} />
      </CurrencySelect> */}

      <Box position="relative" display="flex" borderRadius="20px" height="36px">
        <Box position="absolute" borderRadius="20px" border={`1px solid ${theme.border2}`} width="100%" height="100%" />
        {/* {props.selectedChartToken !== METIS_ADDRESS && props.hasMetisPair && (
          <StyledSwitchButton onClick={() => props.setIsUsdChart(false)} isActive={!props.isUsdChart}>
            FTM
          </StyledSwitchButton>
        )} */}
        <StyledSwitchButton isActive={true}>USD</StyledSwitchButton>
      </Box>

      {!disableCurrencySelect && onCurrencySelect && (
        <CurrencySearchModal
          isOpen={showModal}
          onDismiss={onDismiss}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={selectedCurrency}
          activeCurrencyDirection={activeCurrencyDirection}
        />
      )}
    </CurrencySelectWrapper>
  )
}
