import { useOdosTokenPrice } from './useOdosTokenPrice'
import { useChainId } from 'hooks'
import { Field } from 'state/swap/v3/actions'

export type UseSwapV3PricesProps = {
  from: string
  to: string
}

export const useSwapV3Prices = ({ from, to }: UseSwapV3PricesProps) => {
  const chainId = useChainId()
  const { data: inputPriceUSDResponse } = useOdosTokenPrice({ chainId, tokenAddress: from })
  const { data: outputPriceUSDResponse } = useOdosTokenPrice({ chainId, tokenAddress: to })

  if (!inputPriceUSDResponse || !outputPriceUSDResponse) {
    return
  }

  const { price: inputPriceUSD } = inputPriceUSDResponse
  const { price: outputPriceUSD } = outputPriceUSDResponse

  const outputPrice = +outputPriceUSD <= 0 ? 0 : +inputPriceUSD / +outputPriceUSD
  const inputPrice = +inputPriceUSD <= 0 ? 0 : +outputPriceUSD / +inputPriceUSD

  const prices = {
    [Field.INPUT]: {
      converted: inputPrice,
      usd: +inputPriceUSD,
    },
    [Field.OUTPUT]: {
      converted: outputPrice,
      usd: +outputPriceUSD,
    },
  }

  return prices
}
