import { useEffect, useState } from 'react'
import { HelpCircle } from 'react-feather'
import Skeleton from 'react-loading-skeleton'
import { ImageProps } from 'rebass'
import './logostyle.css'

const BAD_SRCS: { [tokenAddress: string]: true } = {}

export interface LogoProps extends Pick<ImageProps, 'style' | 'alt' | 'className'> {
  src: string | undefined
  size?: string
  disabledSkeleton?: boolean
}

/**
 * Renders an image by sequentially trying a list of URIs, and then eventually a fallback triangle alert
 */
export default function Logo({ src, alt, disabledSkeleton = false, size, ...rest }: LogoProps) {
  const [, refresh] = useState<number>(0)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (src && !disabledSkeleton) {
      const img = new Image()

      img.onload = () => {
        setIsLoaded(true)
      }

      img.src = src
    }
  }, [src, disabledSkeleton])

  if (!src) return <HelpCircle {...rest} />

  if (src in BAD_SRCS) return null

  if (!isLoaded && !disabledSkeleton)
    return <Skeleton style={{ maxWidth: size, width: size, height: size }} circle containerClassName="logoStyle" />

  if (src) {
    return (
      <img
        {...rest}
        alt={alt}
        src={src}
        width={size}
        height={size}
        onError={() => {
          if (src) BAD_SRCS[src] = true
          refresh((i) => i + 1)
        }}
      />
    )
  }

  return <HelpCircle {...rest} />
}
