export type DebankChain = {
  id: string
  communityId: number
  name: string
  nativeTokenId: string
  logoUrl: string
  wrappedTokenId: string
  isSupportPreExec: boolean
}
export type DebankChainList = DebankChain[]

export const debankChainList: DebankChainList = [
  {
    id: 'eth',
    communityId: 1,
    name: 'Ethereum',
    nativeTokenId: 'eth',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/eth/42ba589cd077e7bdd97db6480b0ff61d.png',
    wrappedTokenId: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    isSupportPreExec: true,
  },
  {
    id: 'bsc',
    communityId: 56,
    name: 'BSC',
    nativeTokenId: 'bsc',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/bsc/7c87af7b52853145f6aa790d893763f1.png',
    wrappedTokenId: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    isSupportPreExec: true,
  },
  {
    id: 'xdai',
    communityId: 100,
    name: 'Gnosis',
    nativeTokenId: 'xdai',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/xdai/8b5320523b30bd57a388d1bcc775acd5.png',
    wrappedTokenId: '0xe91d153e0b41518a2ce8dd3d7944fa863463a97d',
    isSupportPreExec: true,
  },
  {
    id: 'pol',
    communityId: 137,
    name: 'POL',
    nativeTokenId: 'pol',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/matic/d3d807aff1a13e9ba51a14ff153d6807.png',
    wrappedTokenId: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    isSupportPreExec: true,
  },
  {
    id: 'ftm',
    communityId: 250,
    name: 'Fantom',
    nativeTokenId: 'ftm',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/ftm/700fca32e0ee6811686d72b99cc67713.png',
    wrappedTokenId: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    isSupportPreExec: true,
  },
  {
    id: 'okt',
    communityId: 66,
    name: 'OEC',
    nativeTokenId: 'okt',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/okt/1228cd92320b3d33769bd08eecfb5391.png',
    wrappedTokenId: '0x8f8526dbfd6e38e3d8307702ca8469bae6c56c15',
    isSupportPreExec: false,
  },
  {
    id: 'heco',
    communityId: 128,
    name: 'HECO',
    nativeTokenId: 'heco',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/heco/db5152613c669e0cc8624d466d6c94ea.png',
    wrappedTokenId: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f',
    isSupportPreExec: false,
  },
  {
    id: 'avax',
    communityId: 43114,
    name: 'Avalanche',
    nativeTokenId: 'avax',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/avax/4d1649e8a0c7dec9de3491b81807d402.png',
    wrappedTokenId: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    isSupportPreExec: true,
  },
  {
    id: 'op',
    communityId: 10,
    name: 'Optimism',
    nativeTokenId: 'op',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/op/01ae734fe781c9c2ae6a4cc7e9244056.png',
    wrappedTokenId: '0x4200000000000000000000000000000000000006',
    isSupportPreExec: true,
  },
  {
    id: 'arb',
    communityId: 42161,
    name: 'Arbitrum',
    nativeTokenId: 'arb',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/arb/f6d1b236259654d531a1459b2bccaf64.png',
    wrappedTokenId: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
    isSupportPreExec: true,
  },
  {
    id: 'celo',
    communityId: 42220,
    name: 'Celo',
    nativeTokenId: 'celo',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/celo/41da5c1d3c0945ae822a1f85f02c76cf.png',
    wrappedTokenId: '0x471ece3750da237f93b8e339c536989b8978a438',
    isSupportPreExec: false,
  },
  {
    id: 'movr',
    communityId: 1285,
    name: 'Moonriver',
    nativeTokenId: 'movr',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/movr/4b0de5a711b437f187c0d0f15cc0398b.png',
    wrappedTokenId: '0x98878b06940ae243284ca214f92bb71a2b032b8a',
    isSupportPreExec: false,
  },
  {
    id: 'cro',
    communityId: 25,
    name: 'Cronos',
    nativeTokenId: 'cro',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/cro/44f784a1f4c0ea7d26d00acabfdf0028.png',
    wrappedTokenId: '0x5c7f8a570d578ed84e63fdfa7b1ee72deae1ae23',
    isSupportPreExec: true,
  },
  {
    id: 'boba',
    communityId: 288,
    name: 'Boba',
    nativeTokenId: 'boba',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/boba/e43d79cd8088ceb3ea3e4a240a75728f.png',
    wrappedTokenId: '0xdeaddeaddeaddeaddeaddeaddeaddeaddead0000',
    isSupportPreExec: true,
  },
  {
    id: 'metis',
    communityId: 1088,
    name: 'Metis',
    nativeTokenId: 'metis',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/metis/b289da32db4d860ebf6fb46a6e41dcfc.png',
    wrappedTokenId: '0x75cb093e4d61d2a2e65d8e0bbb01de8d89b53481',
    isSupportPreExec: true,
  },
  {
    id: 'btt',
    communityId: 199,
    name: 'BitTorrent',
    nativeTokenId: 'btt',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/btt/2130a8d57ff2a0f3d50a4ec9432897c6.png',
    wrappedTokenId: '0x197a4ed2b1bb607e47a144b9731d7d34f86e9686',
    isSupportPreExec: false,
  },
  {
    id: 'aurora',
    communityId: 1313161554,
    name: 'Aurora',
    nativeTokenId: 'aurora',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/aurora/c7590fd2defb8e7d7dc071166838c33a.png',
    wrappedTokenId: '',
    isSupportPreExec: false,
  },
  {
    id: 'mobm',
    communityId: 1284,
    name: 'Moonbeam',
    nativeTokenId: 'mobm',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/mobm/a8442077d76b258297181c3e6eb8c9cc.png',
    wrappedTokenId: '0xacc15dc74880c9944775448304b263d191c6077f',
    isSupportPreExec: false,
  },
  {
    id: 'sbch',
    communityId: 10000,
    name: 'SmartBch',
    nativeTokenId: 'sbch',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/sbch/d78ac780803e7f0a17b73558f423502e.png',
    wrappedTokenId: '0x3743ec0673453e5009310c727ba4eaf7b3a1cc04',
    isSupportPreExec: false,
  },
  {
    id: 'fuse',
    communityId: 122,
    name: 'Fuse',
    nativeTokenId: 'fuse',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/fuse/ea4c9e12e7f646d42aa8fb07ab8dfec8.png',
    wrappedTokenId: '0x0be9e53fd7edac9f859882afdda116645287c629',
    isSupportPreExec: false,
  },
  {
    id: 'hmy',
    communityId: 1666600000,
    name: 'Harmony',
    nativeTokenId: 'hmy',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/hmy/734c003023531e31c636ae25d5a73172.png',
    wrappedTokenId: '0xcf664087a5bb0237a0bad6742852ec6c8d69a27a',
    isSupportPreExec: true,
  },
  {
    id: 'klay',
    communityId: 8217,
    name: 'Klaytn',
    nativeTokenId: 'klay',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/klay/1df018b8493cb97c50b7e390ef63cba4.png',
    wrappedTokenId: '0xe4f05a66ec68b54a58b17c22107b02e0232cc817',
    isSupportPreExec: true,
  },
  {
    id: 'astar',
    communityId: 592,
    name: 'Astar',
    nativeTokenId: 'astar',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/astar/398c7e0014bdada3d818367a7273fabe.png',
    wrappedTokenId: '0xaeaaf0e2c81af264101b9129c00f4440ccf0f720',
    isSupportPreExec: true,
  },
  {
    id: 'sdn',
    communityId: 336,
    name: 'Shiden',
    nativeTokenId: 'sdn',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/sdn/0baaa4ee7cb16feed71119b062ccd277.png',
    wrappedTokenId: '0x0f933dc137d21ca519ae4c7e93f87a4c8ef365ef',
    isSupportPreExec: true,
  },
  {
    id: 'palm',
    communityId: 11297108109,
    name: 'Palm',
    nativeTokenId: 'palm',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/palm/ece828c59e643b8a8a13aa99145ae7d7.png',
    wrappedTokenId: '0xf98cabf0a963452c5536330408b2590567611a71',
    isSupportPreExec: false,
  },
  {
    id: 'iotx',
    communityId: 4689,
    name: 'IoTeX',
    nativeTokenId: 'iotx',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/iotx/d3be2cd8677f86bd9ab7d5f3701afcc9.png',
    wrappedTokenId: '0xa00744882684c3e4747faefd68d283ea44099d03',
    isSupportPreExec: false,
  },
  {
    id: 'rsk',
    communityId: 30,
    name: 'RSK',
    nativeTokenId: 'rsk',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/rsk/2958b02ef823097b70fac99f39889e2e.png',
    wrappedTokenId: '0x542fda317318ebf1d3deaf76e0b632741a7e677d',
    isSupportPreExec: false,
  },
  {
    id: 'wan',
    communityId: 888,
    name: 'Wanchain',
    nativeTokenId: 'wan',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/wan/f3aa8b31414732ea5e026e05665146e6.png',
    wrappedTokenId: '0xdabd997ae5e4799be47d6e69d9431615cba28f48',
    isSupportPreExec: false,
  },
  {
    id: 'kcc',
    communityId: 321,
    name: 'KCC',
    nativeTokenId: 'kcc',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/kcc/3a5a4ef7d5f1db1e53880d70219d75b6.png',
    wrappedTokenId: '0x4446fc4eb47f2f6586f9faab68b3498f86c07521',
    isSupportPreExec: false,
  },
  {
    id: 'sgb',
    communityId: 19,
    name: 'Songbird',
    nativeTokenId: 'sgb',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/sgb/619f46d574d62a50bdfd9f0e2f47ddc1.png',
    wrappedTokenId: '0x02f0826ef6ad107cfc861152b32b52fd11bab9ed',
    isSupportPreExec: false,
  },
  {
    id: 'evmos',
    communityId: 9001,
    name: 'EvmOS',
    nativeTokenId: 'evmos',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/evmos/26e038b4d5475d5a4b92f7fc08bdabc9.png',
    wrappedTokenId: '0xd4949664cd82660aae99bedc034a0dea8a0bd517',
    isSupportPreExec: false,
  },
  {
    id: 'dfk',
    communityId: 53935,
    name: 'DFK',
    nativeTokenId: 'dfk',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/dfk/233867c089c5b71be150aa56003f3f7a.png',
    wrappedTokenId: '0xccb93dabd71c8dad03fc4ce5559dc3d89f67a260',
    isSupportPreExec: false,
  },
  {
    id: 'tlos',
    communityId: 40,
    name: 'Telos',
    nativeTokenId: 'tlos',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/telos/f9f7493def4c08ed222540bebd8ce87a.png',
    wrappedTokenId: '0xd102ce6a4db07d247fcc28f366a623df0938ca9e',
    isSupportPreExec: false,
  },
  {
    id: 'swm',
    communityId: 73772,
    name: 'Swimmer',
    nativeTokenId: 'swm',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/swm/361526e901cb506ef7074c3678ce769a.png',
    wrappedTokenId: '0x9c765eee6eff9cf1337a1846c0d93370785f6c92',
    isSupportPreExec: false,
  },
  {
    id: 'nova',
    communityId: 42170,
    name: 'Arbitrum Nova',
    nativeTokenId: 'nova',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/nova/06eb2b7add8ba443d5b219c04089c326.png',
    wrappedTokenId: '0x722e8bdd2ce80a4422e880164f2079488e115365',
    isSupportPreExec: true,
  },
  {
    id: 'canto',
    communityId: 7700,
    name: 'Canto',
    nativeTokenId: 'canto',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/canto/47574ef619e057d2c6bbce1caba57fb6.png',
    wrappedTokenId: '0x826551890dc65655a0aceca109ab11abdbd7a07b',
    isSupportPreExec: false,
  },
  {
    id: 'doge',
    communityId: 2000,
    name: 'Dogechain',
    nativeTokenId: 'doge',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/doge/2538141079688a7a43bc22c7b60fb45f.png',
    wrappedTokenId: '0xb7ddc6414bf4f5515b52d8bdd69973ae205ff101',
    isSupportPreExec: false,
  },
  {
    id: 'kava',
    communityId: 2222,
    name: 'Kava',
    nativeTokenId: 'kava',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/step/a09a84faa6fc54a5c86dd41eccd4f9d1.png',
    wrappedTokenId: '0xc86c7c0efbd6a49b35e8714c5f59d99de09a225b',
    isSupportPreExec: false,
  },
  {
    id: 'step',
    communityId: 1234,
    name: 'Step',
    nativeTokenId: 'step',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/step/db79600b8feafe17845617ca9c606dbe.png',
    wrappedTokenId: '0xb58a9d5920af6ac1a9522b0b10f55df16686d1b6',
    isSupportPreExec: false,
  },
  {
    id: 'mada',
    communityId: 2001,
    name: 'Milkomeda',
    nativeTokenId: 'mada',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/mada/cdc4b1112c2c5a2757cbda33f4476b7f.png',
    wrappedTokenId: '0xae83571000af4499798d1e3b0fa0070eb3a3e3f9',
    isSupportPreExec: false,
  },
  {
    id: 'cfx',
    communityId: 1030,
    name: 'Conflux',
    nativeTokenId: 'cfx',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/cfx/eab0c7304c6820b48b2a8d0930459b82.png',
    wrappedTokenId: '0x14b2d3bc65e74dae1030eafd8ac30c533c976a9b',
    isSupportPreExec: true,
  },
  {
    id: 'brise',
    communityId: 32520,
    name: 'Bitgert',
    nativeTokenId: 'brise',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/brise/4f6c040cf49f4d8c4eabbad7cd2f4ae4.png',
    wrappedTokenId: '0x0eb9036cbe0f052386f36170c6b07ef0a0e3f710',
    isSupportPreExec: true,
  },
  {
    id: 'ckb',
    communityId: 71402,
    name: 'Godwoken',
    nativeTokenId: 'ckb',
    logoUrl: 'https://static.debank.com/image/chain/logoUrl/ckb/e821893503104870d5e73f56dbd73746.png',
    wrappedTokenId: '0xc296f806d15e97243a08334256c705ba5c5754cd',
    isSupportPreExec: false,
  },
]
