import { useChainId } from 'hooks'
import { debankChainList } from '../../constatnts'

export const useCurrentChainDebankid = () => {
  const chainId = useChainId()

  const currentDebankChain = debankChainList.find((chain) => chain.communityId === chainId)
  const currentChainDebankId = currentDebankChain?.id

  return currentChainDebankId
}
