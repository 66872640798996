// import { Interface } from '@ethersproject/abi';

import BigNumber from 'bignumber.js'
// import Web3 from 'web3';
import { ethers } from 'ethers'
import { getContract } from './getContract'
import { ChainId, currentMainNetwork } from 'sdk'
import { MultiCallAbi3 } from 'shared/abi/Multicall3Abi'
import { MULTICALL_ADDRESSES } from 'sdk/constants'
// import { useActiveWeb3React } from '../hooks/useActiveWeb3React';

export const DEFAULT_GAS_PRICE = 5

export interface UserSettings {
  gasPrice: number
}
export const BIG_TEN = new BigNumber(10)

export const VERSION = 1.01

/**
 * Take a formatted amount, e.g. 15 BNB and convert it to full decimal value, e.g. 15000000000000000
 */
export const getDecimalAmount = (amount: BigNumber, decimals = 18) => {
  return new BigNumber(amount).times(BIG_TEN.pow(decimals))
}

export const GAS_SETTINGS = {
  default: DEFAULT_GAS_PRICE,
  fast: 10,
  reallyfast: 15,
}

export const getDefaultGasPrice = () => {
  return DEFAULT_GAS_PRICE
}

export const getGasPriceInWei = (amountInGwei: number) => {
  return getDecimalAmount(new BigNumber(amountInGwei), 9)
}

export const getDefaultSettings = (): UserSettings => ({
  gasPrice: GAS_SETTINGS.default,
})

export const getSettings = (): UserSettings => {
  try {
    return getDefaultSettings()
  } catch (error) {
    return getDefaultSettings()
  }
}

export interface Call {
  address: string // Address of the contract
  name: string // Function name on the contract (example: balanceOf)
  params?: any[] // Function params
}

export const getMulticallContract: any = (signer: any, chainId: number) => {
  return getContract(MULTICALL_ADDRESSES[chainId], MultiCallAbi3, signer)
}

//hangs if one of the calls reverts
export const multicall = async <T = any>(
  abi: any[],
  calls: Call[],
  signer?: ethers.Signer | ethers.providers.Provider,
  chainId?: ChainId
): Promise<T> => {
  try {
    const multi = getMulticallContract(signer, chainId)
    const itf = new ethers.utils.Interface(abi)
    const calldata = calls.map((call) => [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)])
    const { returnData } = await multi.aggregate(calldata, { blockTag: 'pending' })

    const malformedResult: any[] = []

    returnData.forEach((v) => {
      malformedResult.push(v[1])
    })
    const res = returnData.map((call: any, i: any) => {
      if (call == '0x') {
        call =
          '0x000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'
      }
      return itf.decodeFunctionResult(calls[i].name, call)
    })

    return res
  } catch (error) {
    throw new Error(error as any)
  }
}

//if one of the calls reverts it will return 0 and not hang the request
export const multicallFailSafe = async <T = any>(
  abi: any[],
  calls: Call[],
  signer?: ethers.Signer | ethers.providers.Provider,
  chainId?: ChainId
): Promise<any> => {
  try {
    const multi = getMulticallContract(signer, chainId)
    const itf = new ethers.utils.Interface(abi)

    const calldata = calls.map((call) => [
      call.address.toLowerCase(),
      true,
      itf.encodeFunctionData(call.name, call.params),
    ])
    const returnData = await multi.aggregate3(calldata, { blockTag: 'pending' })
    const malformedResult: any[] = []

    returnData.forEach((v) => {
      malformedResult.push(v[1])
    })

    const res = malformedResult.map((call: any, i: any) => {
      if (call == '0x') {
        call =
          '0x000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'
      }
      return itf.decodeFunctionResult(calls[i].name, call)
    })
    return res
  } catch (error) {
    throw new Error(error as any)
  }
}
