import React from 'react'

import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const StyledSVG = styled.svg<{ size: string; stroke?: string; fill?: string }>`
  animation: 3s ${rotate} linear infinite;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  path {
    stroke: ${({ stroke, theme }) => stroke ?? theme.accent};
    fill: ${({ fill }) => fill ?? 'none'};
  }
`

/**
 * Takes in custom size and stroke for circle color, default to primary color as fill,
 * need ...rest for layered styles on top
 */
export default function Loader({
  size = '16px',
  stroke,
  fill = 'none',
  ...rest
}: {
  size?: string
  stroke?: string
  fill?: string
  [k: string]: any
}) {
  return (
    <StyledSVG size={size} stroke={stroke} fill={fill} {...rest} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 13.5V16.5M12 12L14.25 14.25L12 12ZM13.5 9H16.5H13.5ZM6 6L3.75 3.75L6 6ZM12 6L14.25 3.75L12 6ZM6 12L3.75 14.25L6 12ZM1.5 9H4.5H1.5ZM9 1.5V4.5V1.5Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSVG>
  )
}
