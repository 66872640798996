import axios from 'axios'
import React from 'react'
import axios1Inch from 'utils/1inch/axiosInstance'

const simplexInstance = axios.create({
  baseURL: 'https://payments-api.tomb.com',
})

export const simplexApi = {
  checkAuth: (data: { authentication: string }) =>
    simplexInstance.get('/auth/check', {
      headers: { Authentication: data.authentication },
    }),
  getAuthTimestamp: () => simplexInstance.get('/auth/welcome'),
  authorize: async (data: { signature: string; timestamp: number; address: string }) =>
    await simplexInstance.post('/auth', data),

  getQuote: async (data: { amount: number; authentication: string }) =>
    await simplexInstance.post<{
      quoteId: string
      validUntil: string
      digitalMoney: { currency: string; amount: number }
      fiatMoney: { currency: string; base_amount: number; total_amount: number }
    }>('/quote', { amount: data.amount }, { headers: { Authentication: data.authentication } }),

  requestPayment: async (data: { quoteId: string; quoteDate: string; authentication: string }) =>
    await simplexInstance.post<{ paymentId: string }>('/request', data, {
      headers: { Authentication: data.authentication },
    }),
}

export const api = {
  deBankApi: axios.create({
    baseURL: 'https://debankcache.tethyslabs.workers.dev/v1',
  }),
  simplexApi,
  // firebirdApi: axios.create({
  //   baseURL: 'https://router.firebird.finance/aggregator/v1',
  //   headers: { 'API-KEY': 'firebird_lif3_prod_230323' },
  // }), dead API
  coingeckoApi: axios.create({
    baseURL: 'https://pro-api.coingecko.com/api/v3',
    headers: { 'x-cg-pro-api-key': 'CG-BQ8mb7aut1hmNytgwmHofZoU' },
  }),
  faucetApi: axios.create({
    baseURL: 'https://faucet.lif3.com',
    validateStatus: (status) => {
      return status === 200 || status === 429
    },
  }),
  OneInchApi: axios1Inch,
  odosSwap: axios.create({
    baseURL: 'https://api.odos.xyz',
    headers: {
      'Content-Type': 'application/json',
    },
  }),
}

export const ApiContext = React.createContext<typeof api | null>(null)
