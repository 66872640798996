import axios, { CancelTokenSource } from 'axios'
import { useQuery } from 'react-query'
import { ChainId } from 'sdk'
import { api } from 'shared'

export type UseOdosTokenPriceProps = {
  chainId: ChainId
  tokenAddress: string
}

export const useOdosTokenPrice = ({ chainId, tokenAddress }: UseOdosTokenPriceProps) => {
  return useQuery(
    ['odos-token-price', chainId, tokenAddress],
    async ({ signal }) => {
      const source: CancelTokenSource = axios.CancelToken.source()

      if (signal) {
        signal.addEventListener('abort', () => {
          source.cancel('Request canceled by React Query.')
        })
      }

      try {
        const response = await api.odosSwap.get(`/pricing/token/${Number(chainId)}/${String(tokenAddress)}`, {
          cancelToken: source.token,
        })

        return response.data
      } catch (error) {
        if (axios.isCancel(error)) {
          console.error('Request canceled:', error)
        } else {
          throw error
        }
      }
    },
    {
      enabled: Boolean(tokenAddress),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: 60000,
    }
  )
}
