import { formatAmount } from 'utils/formatAmount'
import { formatToCurrency } from 'utils/formatters'

interface Data {
  inputAmount: string
  outputAmount: string
  inputSymbol: string
  outputSymbol: string
  inputChain?: number
  outputChain?: number
}

export function getPopupText(data: Data, type: 'V3' | 'LIFI'): string {
  const { inputAmount, outputAmount, inputSymbol, outputSymbol, inputChain, outputChain } = data

  return type === 'LIFI'
    ? `${inputAmount} ${inputSymbol} (${inputChain}) exchanged for ${outputAmount} ${outputSymbol} (${outputChain})`
    : `${inputAmount} ${inputSymbol} exchanged for ${outputAmount} ${outputSymbol}`
}

export const createNumberForPopUp = (amount: number) => {
  let result
  if (amount < 0.0001) {
    const amountStr = formatAmount(amount)
    const firstNonZeroIndex = amountStr.slice(2).search(/[1-9]/) + 2
    result = amountStr.slice(0, firstNonZeroIndex + 1)
  } else {
    result = amount.toFixed(4)
  }

  if (result.includes('.')) {
    result = result.replace(/0+$/, '')
    if (result.endsWith('.')) {
      result = result.slice(0, -1)
    }
  }

  return formatToCurrency(result)
}
