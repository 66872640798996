import styled, { keyframes } from 'styled-components'
import { darken, rgba } from 'polished'

import { RowBetween } from '../Row'
import { ChevronDown } from 'react-feather'
import { Button as RebassButton, ButtonProps } from 'rebass/styled-components'
import { SwitchTransition, CSSTransition } from 'react-transition-group'

export const Base = styled(RebassButton)<{
  padding?: string
  width?: string
  borderRadius?: string
  altDisabledStyle?: boolean
  fontSize?: string
}>`
  padding: ${({ padding }) => (padding ? padding : '18px')};
  width: ${({ width }) => (width ? width : '100%')};
  font-weight: 700;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
  text-align: center;
  font-family: inherit;
  border-radius: 40px;
  border-radius: ${({ borderRadius }) => borderRadius && borderRadius};
  outline: none;
  border: none;
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  &:disabled {
    cursor: auto;
  }

  > * {
    user-select: none;
  }
`

export const ButtonModal = styled(Base)<{ size?: 'large' | 'medium' | 'small' }>`
  background: ${({ theme }) => (theme.darkMode ? '#1E1E1E' : '#FFFFFF')};
  color: ${({ theme }) => theme.primaryText1};
  border-radius: 30px;
  transition: all 0.3s;
  box-shadow: 0px 4px 40px rgb(0 0 0 / 5%), 0px 2px 17.4375px rgb(0 0 0 / 10%), 0px 4px 6.5px rgb(0 0 0 / 7%),
    0px 0.875px 2.3125px rgb(0 0 0 / 5%);

  padding: ${({ size }) => {
    if (size === 'small') {
      return '0 20px;'
    }

    if (size === 'medium') {
      return '0 40px;'
    }

    return '0 60px;'
  }};
  height: ${({ size }) => {
    if (size === 'small') {
      return '24px;'
    }
    if (size === 'medium') {
      return '36px;'
    }

    return '48px;'
  }};
  border: none;

  &:hover {
    box-shadow: 0px 20px 40px rgb(0 0 0 / 14%), 0px 10.125px 17.4375px rgb(0 0 0 / 9%), 0px 4px 6.5px rgb(0 0 0 / 7%),
      0px 0.875px 2.3125px rgb(0 0 0 / 5%);
  }
`

export const ButtonPrimary = styled(Base)<{ loading?: boolean }>`
  overflow: hidden;
  background: ${({ theme }) => theme.accent};
  color: ${({ theme }) => theme.primaryText1};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'all')};
  transition: all 0.3s;

  &:hover {
    background: ${({ theme, loading }) => (loading ? '' : theme.accentHover)};
    color: #ffffff;
  }

  &:disabled {
    cursor: auto;
    outline: none;
    pointer-events: none;
    background: ${({ theme }) => rgba(theme.white, 0.75)};
    color: ${({ theme }) => theme.text1};
  }
`

export const ButtonPrimaryNormal = styled(Base)`
  background: ${({ theme }) => theme.accent};
  color: ${({ theme }) => theme.primaryText1};
  padding: 11px 30px;
  transition: all 0.3s;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.menuBoxShadow};
  }
  &:hover {
    opacity: 0.9;
    color: #ffffff;
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.menuBoxShadow};
  }
  &:disabled {
    background: ${({ theme, altDisabledStyle, disabled }) =>
      altDisabledStyle ? (disabled ? theme.primary2 : theme.accent) : theme.primary2};
    color: ${({ theme, altDisabledStyle, disabled }) =>
      altDisabledStyle ? (disabled ? theme.text3 : 'white') : theme.text3};
    cursor: auto;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
    opacity: ${({ altDisabledStyle }) => (altDisabledStyle ? '0.5' : '1')};
  }
`

const anime = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`

export const ButtonPrimaryGradient = styled(Base)<{ loading?: boolean }>`
  position: relative;
  overflow: hidden;
  font-size: 16px;
  font-weight: 700;
  background: ${({ theme }) => theme.buttonGradientBg};
  color: ${({ theme }) => theme.primaryText1};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'all')};
  transition: all 0.3s;
  background-size: 600%;
  animation: ${anime} 16s linear infinite;

  &::after {
    position: absolute;
    margin-top: -70px;
    z-index: -1;
    filter: blur(30px);
    background: ${({ theme }) => theme.buttonGradientBg};
    opacity: 0.8;
    content: '';
    background-size: 600%;
    animation: ${anime} 16s linear infinite;
  }

  &::before {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-size: 200% auto;
    background-image: ${({ theme }) => theme.buttonGradientHover};
    opacity: 0;
    transition: all 0.7s;
    content: '';
  }

  &:hover::before {
    opacity: 1;
    background-position: right center;
  }

  & > * {
    position: relative;
    z-index: 2;
  }

  &:disabled {
    background: ${({ theme, altDisabledStyle, disabled }) =>
      altDisabledStyle ? (disabled ? theme.primary2 : theme.accent) : theme.primary2};
    color: ${({ theme, altDisabledStyle, disabled }) =>
      altDisabledStyle ? (disabled ? theme.text3 : 'white') : theme.text3};
    cursor: auto;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
    opacity: ${({ altDisabledStyle }) => (altDisabledStyle ? '0.5' : '1')};
  }
`

export const ButtonLight = styled(Base)`
  background-color: ${({ theme }) => theme.primary5};
  color: ${({ theme }) => theme.primaryText1};
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.03, theme.primary5)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.03, theme.primary5)};
  }
  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.03, theme.primary5)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.05, theme.primary5)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.primary5)};
  }
  :disabled {
    opacity: 0.4;
    :hover {
      cursor: auto;
      background-color: ${({ theme }) => theme.primary5};
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
    }
  }
`

export const ButtonGray = styled(Base)`
  background-color: ${({ theme }) => theme.primary2};
  color: ${({ theme }) => theme.text2};
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s;
  &:focus {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg4)};
  }
  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg4)};
  }
  &:active {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.1, theme.bg4)};
  }
`

export const ButtonPrimaryWhite = styled(Base)`
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.text1};
  border-radius: 10px;
  font-size: 16px;
  font-weight: 700;
  transition: all 0.3s;

  &:focus {
    background-color: ${({ theme, disabled }) => !disabled && theme.componentBg3};
  }
  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.componentBg3};
  }
  &:active {
    background-color: ${({ theme, disabled }) => !disabled && theme.componentBg3};
  }
  &:disabled {
    opacity: 0.6;
  }
`

export const ButtonSecondary = styled(Base)`
  border: 1px solid ${({ theme }) => theme.mixedAccentColor};
  color: ${({ theme }) => theme.mixedAccentColor};
  background-color: transparent;
  font-size: 14px;
  border-radius: 30px;
  padding: ${({ padding }) => (padding ? padding : '10px')};
  transition: all 0.3s;
  &:focus {
    opacity: 0.8;
  }
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.8;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
  a:hover {
    text-decoration: none;
  }
`

export const ButtonPink = styled(Base)`
  background-color: ${({ theme }) => theme.primary1};
  color: white;
  transition: all 0.3s;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.primary1)};
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.primary1)};
    background-color: ${({ theme }) => darken(0.1, theme.primary1)};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.primary1};
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonUNIGradient = styled(ButtonPrimary)`
  color: white;
  padding: 4px 8px;
  height: 36px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.primary2};
  background: radial-gradient(174.47% 188.91% at 1.84% 0%, #ff007a 0%, #0094ec 100%), #edeef2;
  width: fit-content;
  position: relative;
  cursor: pointer;
  border: none;
  white-space: no-wrap;
  transition: all 0.3s;
  :hover {
    opacity: 0.8;
  }
  :active {
    opacity: 0.9;
  }
`

export const ButtonPoolTokenStyled = styled(Base)`
  border: none;
  background-color: ${({ theme }) => theme.componentBg2};
  color: ${({ theme }) => theme.primaryText1};
  border-radius: 20px;
  box-shadow: ${({ theme }) => theme.inputShadow};

  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonEmpty = styled(Base)`
  background-color: transparent;
  color: ${({ theme }) => theme.primary1};
  display: flex;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;

  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonWhite = styled(Base)`
  border: 1px solid #edeef2;
  background-color: ${({ theme }) => theme.bg1};
  color: black;
  transition: all 0.3s;
  &:focus {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    box-shadow: 0 0 0 1pt ${darken(0.05, '#edeef2')};
  }
  &:hover {
    box-shadow: 0 0 0 1pt ${darken(0.1, '#edeef2')};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${darken(0.1, '#edeef2')};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonBordered = styled(Base)`
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: transparent;
  color: ${({ theme }) => theme.primaryText1};
  transition: all 0.3s;
`

export const ConfirmedIcon = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.text1};
  transition: opacity 0.5s ease;
  z-index: 2;
`

const ButtonConfirmedStyle = styled(Base)<{ loading?: boolean }>`
  padding: 0;
  background: ${({ theme, loading }) => (loading ? theme.componentBg2 : theme.componentBg3)};
  color: ${({ theme }) => theme.primaryText1};
  border: 1px solid transparent;
  width: 54px;
  border-radius: 50%;
  height: 54px;
  flex-shrink: 0;
  transition: width 0.3s ease;

  &:disabled {
    opacity: 1;
    cursor: auto;
  }
`

const ButtonErrorStyle = styled(Base)`
  background-color: ${({ theme }) => theme.red1};
  border: 1px solid ${({ theme }) => theme.red1};
  transition: all 0.3s;
  border-radius: 10px;

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.red1)};
    background-color: ${({ theme }) => darken(0.05, theme.red1)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.red1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.red1)};
    background-color: ${({ theme }) => darken(0.1, theme.red1)};
  }
  &:disabled {
    opacity: 0.4;
    cursor: auto;
    box-shadow: none;
    background-color: ${({ theme }) => theme.red1};
    border: 1px solid ${({ theme }) => theme.red1};
  }
`

export const ButtonGold = styled(Base)`
  background-color: ${({ theme }) => theme.primary1};
  color: ${({ theme }) => theme.text1};
  font-weight: 700;
  font-size: 14px;
  transition: all 0.3s;
  padding: 8px 14px;
`

export const ButtonDisabled = styled(Base)`
  background-color: ${({ theme }) => theme.componentBg3};
  color: ${({ theme }) => theme.text1};
  font-weight: 500;
  transition: all 0.3s;
  padding: 8px 14px;
`

export function ButtonConfirmed({
  confirmed,
  altDisabledStyle,
  ...rest
}: { confirmed?: boolean; altDisabledStyle?: boolean; loading?: boolean } & ButtonProps) {
  return (
    <SwitchTransition>
      <CSSTransition key={rest.key as any} addEndListener={() => {}} classNames="confirmed-button">
        {confirmed ? (
          <ButtonConfirmedStyle {...rest} />
        ) : (
          <ButtonPrimaryWhite {...rest} altDisabledStyle={altDisabledStyle} />
        )}
      </CSSTransition>
    </SwitchTransition>
  )
}

export function ButtonError({
  error,
  altDisabledStyle,
  ...rest
}: { error?: boolean; altDisabledStyle?: boolean } & ButtonProps) {
  if (error) {
    return <ButtonErrorStyle {...rest} />
  } else {
    return <ButtonPrimaryWhite {...rest} altDisabledStyle={altDisabledStyle} />
  }
}

export function ButtonDropdown({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonPrimary {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonPrimary>
  )
}

export function ButtonDropdownGrey({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonGray {...rest} disabled={disabled} style={{ borderRadius: '20px' }}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonGray>
  )
}

export function ButtonPoolToken({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonPoolTokenStyled {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonPoolTokenStyled>
  )
}

export function ButtonRadio({ active, ...rest }: { active?: boolean } & ButtonProps) {
  if (!active) {
    return <ButtonWhite {...rest} />
  } else {
    return <ButtonPrimary {...rest} />
  }
}

// export function ButtonRadioChecked({ active = false, children, ...rest }: { active?: boolean } & ButtonProps) {
//   const theme = useTheme()

//   if (!active) {
//     return (
//       <ButtonOutlined $borderRadius="12px" padding="12px 8px" {...rest}>
//         <RowBetween>{children}</RowBetween>
//       </ButtonOutlined>
//     )
//   } else {
//     return (
//       <ActiveOutlined {...rest} padding="12px 8px" $borderRadius="12px">
//         <RowBetween>
//           {children}
//           <CheckboxWrapper>
//             <Circle>
//               <ResponsiveCheck size={13} stroke={theme.white} />
//             </Circle>
//           </CheckboxWrapper>
//         </RowBetween>
//       </ActiveOutlined>
//     )
//   }
// }
