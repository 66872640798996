import React, { useCallback, useContext } from 'react'
import styled from 'styled-components'
import { LayoutContainerContext } from '../context'
import { breakpoints } from '../constants/breakpoints'

const StyledContainer = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  margin: 0 auto;
  padding: 0 20px;

  @media (max-width: ${(props) => props.width}) {
    width: 100%;
  }
`

type ContainerProps = {
  children: JSX.Element
  breakpoint?: 'xlDown' | 'lgDown' | 'mdDown' | 'smDown'
}

export const useLayoutContainer = (): React.FC<ContainerProps> => {
  const context = useContext(LayoutContainerContext)

  const withByBreakpointContext =
    context?.breakpoint && context?.breakpoints ? context.breakpoints[context?.breakpoint] : undefined

  const Container = useCallback<React.FC<ContainerProps>>((props) => {
    const widthByBreakpointProperty =
      props.breakpoint && context?.breakpoints ? context.breakpoints[props.breakpoint] : undefined

    return (
      <StyledContainer width={`${widthByBreakpointProperty ?? withByBreakpointContext ?? breakpoints.mdDown}px`}>
        {props.children}
      </StyledContainer>
    )
  }, [])

  return Container
}
