import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../state'
import { BigNumber } from 'ethers'
import { BigNumberish } from '@ethersproject/bignumber'

// combines the block timestamp with the user setting to give the deadline that should be used for any submitted transaction
export default function useTransactionDeadline(): BigNumberish | undefined {
  const ttl = useSelector<AppState, number>((state) => state.user.userDeadline)

  return useMemo(() => {
    if (ttl) return BigNumber.from(Date.now()).add(ttl)
    return undefined
  }, [ttl])
}
