import { Currency, Percent, TradeType } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import Row from 'components/Row'
import styled, { keyframes, useTheme } from 'styled-components/macro'
import { AdvancedSwapDetails } from './AdvancedSwapDetails'
import GasEstimateTooltip from './GasEstimateTooltip'
import TradePrice from './TradePrice'
import { InterfaceTrade } from 'hooks/v3/types'
import { SUPPORTED_GAS_ESTIMATE_CHAIN_IDS } from 'constants/v3'
import { Text } from 'rebass'
import { SelectViewToggle } from 'components/SelectViewToggle'
import { useLocalStorage } from 'shared'

const StyledPolling = styled.div`
  display: flex;
  height: 16px;
  width: 16px;
  margin-right: 2px;
  margin-left: 10px;
  align-items: center;
  color: ${({ theme }) => theme.accentText};
  transition: 250ms ease color;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `}
`

const StyledPollingDot = styled.div`
  width: 8px;
  height: 8px;
  min-height: 8px;
  min-width: 8px;
  border-radius: 50%;
  position: relative;
  background-color: ${({ theme }) => theme.componentBg4};
  transition: 250ms ease background-color;
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  animation: ${rotate360} 1s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  transform: translateZ(0);
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 2px solid ${({ theme }) => theme.border1};
  background: transparent;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: relative;
  transition: 250ms ease border-color;
  left: -3px;
  top: -3px;
`

const SwapDetailsWrapper = styled.div`
  padding-top: ${({ theme }) => theme.grids.md};
`

interface SwapDetailsInlineProps {
  trade: InterfaceTrade<Currency, Currency, TradeType> | undefined
  syncing: boolean
  loading: boolean
  allowedSlippage: Percent
  alwaysOpen?: boolean
  prices: any
}

export default function SwapDetailsDropdown({
  trade,
  syncing,
  loading,
  allowedSlippage,
  alwaysOpen = false,
  prices,
}: SwapDetailsInlineProps) {
  const theme = useTheme()
  const { chainId } = useWeb3React()
  const [showDetails, setShowDetails] = useLocalStorage('v3-details-open', false)

  return (
    <SelectViewToggle
      isOpen={showDetails || alwaysOpen}
      setIsOpen={setShowDetails}
      showArrow={!alwaysOpen}
      head={
        <Row gap="6px">
          {Boolean(loading || syncing) && (
            <StyledPolling>
              <StyledPollingDot>
                <Spinner />
              </StyledPollingDot>
            </StyledPolling>
          )}
          {trade ? (
            <TradePrice currencies={trade.executionPrice as any} prices={prices} />
          ) : loading || syncing ? (
            <Text>Fetching best price...</Text>
          ) : null}
          {!trade?.gasUseEstimateUSD ||
          showDetails ||
          !chainId ||
          !SUPPORTED_GAS_ESTIMATE_CHAIN_IDS.includes(chainId) ? null : (
            <GasEstimateTooltip trade={trade} loading={syncing || loading} disabled={showDetails} />
          )}
        </Row>
      }
    >
      {trade && (
        <SwapDetailsWrapper data-testid="advanced-swap-details">
          <AdvancedSwapDetails trade={trade} allowedSlippage={allowedSlippage} syncing={syncing} />
        </SwapDetailsWrapper>
      )}
    </SelectViewToggle>
  )
}
